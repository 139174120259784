.navbar {
  $self: &;

  /* First Level */
  &__list {
    display: flex;
    font-size: 1.5rem;
    align-items: center;
    justify-content: space-around;
    height: 100%;
    line-height: 1.1;
    margin: 0;
  }

  &__item {
    height: 100%;
    position: relative;
    font-weight: bold;
    margin: 0 1.4rem;

    &.active {
      #{$self}__link {
        cursor: default;
        color: $primary-color;

        &::before {
          background: $primary-color;
        }
      }

      #{$self}__icon {
        fill: $primary-color;
      }
    }

    &:hover #{$self}__link {
      &::before {
        width: 100%;
      }
    }
  }

  &__link {
    color: #585857;
    display: flex;
    padding: 1rem 0;
    transition: all .3s;
    position: relative;
    height: 100%;
    align-items: center;

    &::before {
      width: 0;
      height: .4rem;
      position: absolute;
      left: 0;
      bottom: 0;
      background: $primary-color;
      content: "";
      transition: all .3s;
    }

    @media (min-width: $md) {
      padding-top: 5rem;
      padding-bottom: 5rem;
    }
  }

  &__text {
    display: inline-block;
    vertical-align: middle;
    white-space: nowrap;
  }

  &__icon {
    fill: $gray--medium;
    width: 2.4rem;
    height: 2.4rem;
    margin-right: 1.5rem;
    transition: all .3s;
  }

  &__arr {
    margin-left: .5rem;
    width: .6rem;
    height: .6rem;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-image: url("data:image/svg+xml,%3Csvg width='6' height='4' viewBox='0 0 6 4' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.861389 0.988525L2.99983 3.12696L5.13827 0.988525' stroke='black' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  }
  /* First Level */

  /* Second Level */
  &__sublist {
    background: $white;
    border-top: 1px solid $gray--medium;
    z-index: $dropdownZIndex;
    font-size: 1.4rem;
    min-width: 31rem;
    padding: .5rem 0;

    box-shadow: 0px 5px 30px rgba(0, 0, 0, 0.1) inset;
    box-shadow: inset 0 .5rem 2rem -.5rem rgba($black, 0.1);

    &--subcategories {
      min-width: 40rem;
    }
  }

  &__subitem {
    position: relative;
    font-weight: normal;
    font-weight: 600;
    font-size: 1.4rem;

    &:not(:first-child) {
      border-top: 1px solid #F8F8F8;
    }

    @media (min-width: $md) {
      &:hover #{$self}__sublink {
        color: $primary-color;
      }
    }
  }

  &__sublink {
    display: block;
    padding: 1.2rem 3.5rem 1.2rem 2rem;
    color: #585857;
    transition: all .3s;
    position: relative;

    &::after {
      content: '';
      right: 2rem;
      top: 50%;
      position: absolute;
      transform: translateY(-50%);
      width: .9rem;
      height: .9rem;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      background-image: url("data:image/svg+xml,%3Csvg width='6' height='9' viewBox='0 0 6 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.13025 4.50002C5.13025 4.66132 5.06867 4.8226 4.94575 4.94558L1.07591 8.81537C0.829744 9.06154 0.430623 9.06154 0.184552 8.81537C-0.0615175 8.5693 -0.0615175 8.17026 0.184552 7.92407L3.6088 4.50002L0.184672 1.07596C-0.0613983 0.829789 -0.0613983 0.430787 0.184672 0.184737C0.430742 -0.0615521 0.829863 -0.0615521 1.07603 0.184737L4.94587 4.05447C5.06881 4.17751 5.13025 4.33879 5.13025 4.50002Z' fill='%23D1D1D1'/%3E%3C/svg%3E%0A");
    }

    &:hover {
      color: $primary-color;

      &::after {
        background-image: url("data:image/svg+xml,%3Csvg width='6' height='9' viewBox='0 0 6 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.13025 4.50002C5.13025 4.66132 5.06867 4.8226 4.94575 4.94558L1.07591 8.81537C0.829744 9.06154 0.430623 9.06154 0.184552 8.81537C-0.0615175 8.5693 -0.0615175 8.17026 0.184552 7.92407L3.6088 4.50002L0.184672 1.07596C-0.0613983 0.829789 -0.0613983 0.430787 0.184672 0.184737C0.430742 -0.0615521 0.829863 -0.0615521 1.07603 0.184737L4.94587 4.05447C5.06881 4.17751 5.13025 4.33879 5.13025 4.50002Z' fill='%235EBA99'/%3E%3C/svg%3E%0A");
      }
    }
  }
  /* Second Level */

  /* Interactive */
  &__sublist {
    @include hide-element;
  }

  @media (min-width: $md) {
    &__item:hover {
      .navbar__link {
        color: $primary-color;
      }
    }

    &__item:hover &__sublist {
      animation: .2s fadeInUp;

      @include show-element(absolute, 100%);
    }
  }
  /* Interactive */

  /* ---------------------- Mobile Version ---------------------- */
  @media (max-width: $md--mw) {
    @at-root {
      .navbar {
        position: fixed;
        width: 100vw;
        height: 100vh;
        left: -100%;
        top: 0;
        // background: $primary-color;
        transition: all .3s;

        /* First Level */
        &__list {
          flex-direction: column;
          overflow-x: hidden;
          overflow-y: auto;
          justify-content: center;
        }

        &__item {
          height: auto;
          width: 20rem;

          &.active {
            #{$self}__link {
              color: $gray--medium;
            }

            #{$self}__icon {
              fill: $gray--medium;
            }
          }
        }

        &__link {
          color: $white;
          display: block;
          height: auto;
          padding-top: 2rem;
          padding-bottom: 2rem;

          &::before {
            display: none;
          }
        }

        &__arr {
          color: $white;
        }
        /* First Level */

        /* Second Level */
        &__sublist {
          background: transparent;
          border: none;
          padding: 1rem 0 1.5rem;
        }

        &__subitem {
          #{$self}__arr {
            &::before {
              content: "\f078";
            }
          }
        }

        &__sublink {
          color: #fefefe;
        }

        .is-opened {
          > #{$self}__sublist {
            @include show-element;
          }
        }
        /* Second Level */

        /* Third Mob Level */
        &__deeplist {
          background: transparent;
          padding-left: 2rem;
          border: none;
          min-width: 0;
          border-top: 1px dotted $gray--medium;
          border-bottom: 1px dotted $gray--medium;
          padding-top: .5rem;
          padding-bottom: .5rem;
          margin: .5rem 0;
        }

        &__deepitem {
          position: relative;

          &::before {
            content: "";
            width: .8rem;
            height: .8rem;
            position: absolute;
            left: -.5rem;
            top: 1.25rem;
            background: #ffcc3a;
            border-radius: 50%;
          }
        }

        &__deeplink {
          color: #eee;
        }

        .is-opened {
          > #{$self}__deeplist {
            @include show-element;

            margin-top: .5rem;
            margin-bottom: .5rem;
          }
        }
        /* Third Mob Level */
      }

      .is-navbar-opened {
        .navbar {
          // background: $primary-color;
          z-index: $overlayZIndex;
          left: 0;
        }

        .navbar-trigger {
          z-index: $overlayAboveZIndex;

          &__line {
            background: $white;
          }
        }
      }
    }
  }
  /* ---------------------- Mobile Version ---------------------- */

  /* misc */
  &__subcaption {
    background: $primary-color;
    color: $white;
    padding: 1.5rem 2rem;
    display: flex;

    &:hover {
    	color: #fff;
    }

    &:first-child {
      margin-top: -.6rem;
    }
  }
  /* misc */
}

/* ----------- Navbar Toggle ----------- */
.navbar-trigger {
  $self: &;

  width: 3rem;
  height: 2rem;
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
  position: absolute;
  left: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;

  @media (min-width: $md) {
    display: none;
  }

  &::before {
    content: "";
    height: .3rem;
    width: 100%;
    left: 0;
    transform: translateY(-50%);
    top: 50%;
    position: absolute;
    background: $primary-color;
  }

  &__line {
    display: block;
    position: absolute;
    height: .3rem;
    background: $primary-color;
    transition: all .5s ease-in-out;
    left: 0;
    width: 100%;

    &:nth-child(1) {
      top: 0;
    }

    &:nth-child(2) {
      bottom: 0;
    }
  }

  &.is-active {
    &::before {
      display: none;
    }

    #{$self}__line:nth-child(1),
    #{$self}__line:nth-child(2) {
      left: 50%;
      top: 50%;
    }

    #{$self}__line:nth-child(1) {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    #{$self}__line:nth-child(2) {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}
/* ----------- Navbar Toggle ----------- */

body.is-navbar-opened {
  overflow: hidden;
}

@media only screen and (min-device-width: 375px) and (min-device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) { 
    .mob-nav {
    	height: 12rem;
    }

    .mob-nav__link {
    	justify-content: flex-start;
    	padding-top: 2rem;
    }
}

@media only screen 
    and (min-device-width: 414px) 
    and (min-device-height: 896px) 
    and (-webkit-device-pixel-ratio: 2) 
    and (orientation: portrait) { 
    .mob-nav {
    	height: 12rem;
    }

    .mob-nav__link {
    	justify-content: flex-start;
    	padding-top: 2rem;
    }
}

.mob-nav .footer-nav__heading {
	padding: 0 2rem;
}

.mob-nav .footer-nav__heading-link::after {
	opacity: .5;
}

.mob-nav__link-text {
  font-weight: 600;
  font-size: 1.6rem;
}