.header {
  background: $white;
  box-shadow: 0 .5rem 3rem rgba($black, 0.1);
  padding: 1.5rem 0;

  @media (min-width: $md) {
    padding: 0;
  }

  &__main {
    display: flex;
    align-items: center;
    margin: 0 -1.5rem;
    justify-content: space-between;

    > * {
      padding: 0 1.5rem;
    }
  }

  &__logo {
    max-width: 17rem;
  }

  &__nav {
    align-self: stretch;
  }

  &__aside {
    display: flex;
    align-items: center;
  }
}

.lang {
  margin: 0 3rem 0 0;
  white-space: nowrap;
  display: none;

  @media (min-width: $lg) {
    display: block;
  }

  &__item {
    display: inline-block;
    text-transform: uppercase;
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1;

    &:not(:first-child) {
      border-left: 2px solid #585857;
      margin-left: .5rem;
      padding-left: .7rem;
    }

    &.is-active {
      a {
        color: #585857;
        cursor: default;
      }
    }
  }

  &__link {
    color: #C6C6C6;
    transition: all .3s;

    &:hover {
      color: #585857;
    }
  }
}

// fixed header
.header {
  transform: translateX(0%);
  transition: transform .3s;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: $dropdownAboveZIndex;

  @at-root body {
    padding-top: 9rem;

    @media (min-width: $md) {
      padding-top: 11rem;
    }
  }

  &.hided {
    box-shadow: none;
    transform: translateY(-100%);
  }

  &.active {
    box-shadow: 0 1.1rem 1.5rem rgba(35,52,107,.05);
  }
}
