.solution-mode {
  $self: &;

  display: flex;
  flex-direction: column;
  padding: 2rem;
  min-height: 100%;
  background: $white;
  line-height: 1.8;
  font-weight: 500;
  border: 2px solid $secondary-color;
  box-shadow: 0 0.8rem 2rem rgba($black, 0.05);
  position: relative;
  
  &-arrow {
    &:after {
      content: '';
      position: absolute;
      background-image: url("data:image/svg+xml,%3Csvg width='58' height='41' viewBox='0 0 58 41' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='24.4568' y='16.4562' width='6.58252' height='9.68581' transform='rotate(90 24.4568 16.4562)' fill='%23B0E3E6'/%3E%3Crect x='9.92822' y='16.4562' width='6.58252' height='9.68581' transform='rotate(90 9.92822 16.4562)' fill='%23B0E3E6'/%3E%3Cpath d='M57.8738 20.5L23.9386 38.2535L23.9386 2.74648L57.8738 20.5Z' fill='%23B0E3E6'/%3E%3C/svg%3E%0A");
      width: 58px;
      height: 41px;
      right: -11px;
      top: 50%;
      margin-top: -20px;
      z-index: 100;
    }
  }

  &--small {
    #{$self}__heading {
      font-size: 2.2rem;
      line-height: 1.3;
    }

    #{$self}__desc,
    #{$self}__list {
      line-height: 1.3;
    }
  }

  &__heading {
    margin: 0 0 1.5rem;
    font-size: 2.8rem;
    font-weight: 700;
    line-height: 1.5;

    &--small {
      font-size: 2.4rem;
    }

    &--xsmall {
      font-size: 2.2rem;
    }
  }

  &__img-wrap {
    margin-top: auto;
    text-align: center;
  }

  &__img {
    width: 100%;
    height: auto;
  }

  &__group {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    &-img {
      flex: 0 0 16.5rem;
      max-width: 16.5rem;
      position: relative;
      z-index: 2;
    }

    &-list {
      flex: 1 1 auto;
      margin-bottom: 0;

      .list__item {
        &::after {
          content: '';
          position: absolute;
          top: 0.75rem;
          right: 100%;
          width: 6.5rem;
          border-top: 1px solid $secondary-color;
        }
      }
    }
  }
}

.service-section__col {
  &:last-child {
    .solution-mode:after {
      display: none;
    }
  }
}
