@charset "UTF-8";

// 1.Abstracts (Configuration and Helpers)
@import "abstracts/mixins";
@import "abstracts/functions";
@import "abstracts/variables";

// 2.Vendors (from node_modules)
@import "bootstrap/scss/bootstrap-reboot.scss";
@import "bootstrap/scss/bootstrap-grid.scss";
@import "swiper/swiper.scss";
@import "lightgallery.js/src/sass/lightgallery.scss";
@import "choices.js/src/styles/choices.scss";
// @import "pikaday/scss/pikaday.scss";

// 2.Vendors-extensions
@import "vendors-extensions/bootstrap";
@import "vendors-extensions/choices.scss";
// @import "vendors/nouislider.scss";
//@import "vendors/drift-basic.scss";
@import "vendors/simplebar.scss";
//@import "vendors/card.scss";

// 3.Base stuff
@import "base/base";      // maybe reset
@import "base/utilities"; // aka helpers
@import "base/fonts";
@import "base/typography";
@import "base/forms";
@import "base/table";
@import "base/animations";
@import "base/print";

// 4.Layout
@import "layout/main-layout";
@import "layout/header";
@import "layout/footer";
@import "layout/section";
@import "layout/sidebar";
@import "layout/carousels";
@import "layout/docs-section";
@import "layout/page-inner";

// 5.Components
@import "components/buttons";
@import "components/icons";
@import "components/tooltip";
@import "components/soc";
@import "components/breadcrumbs";
@import "components/pagination";
@import "components/navbar";
@import "components/tabs";
@import "components/modal-win";
@import "components/callback";
@import "components/case";
@import "components/service-result";
@import "components/work-steps";
@import "components/service-target";
@import "components/has-decorations";
@import "components/hero-intro";
@import "components/projects";
@import "components/dropdown";
@import "components/service-users";
@import "components/news";
@import "components/news-detail";
@import "components/contacts";
@import "components/promo-carousel";
@import "components/category";
@import "components/brand";
@import "components/agency";
@import "components/team";
@import "components/team-detail";
@import "components/direction";
@import "components/informer";
@import "components/industry";
@import "components/solution";
@import "components/solution-mode";
@import "components/solution-include";
@import "components/tech-start";
@import "components/tech-consume";

// 6.Pages (page-specific styles)
//@import 'pages/inner-page';

// 7.Themes
// @import 'themes/default';

.footer {
  padding-bottom: 7rem;

  @media (min-width: $md) {
    padding-bottom: 0;
  }
}

.mob-nav {
  height: 7rem;
  background: $white;
  box-shadow: 0px 5px 20px rgba(88, 89, 91, 0.3);
  position: fixed;
  margin: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 11;
  padding: 0 15px;

  @media (min-width: $md) {
    display: none;
  }

  &__list {
    height: 100%;
    display: flex;
  }

  &__item {
    flex: 1;
    font-size: 1.2rem;
    font-weight: 600;

    &:not(:first-child) {
      border-left: 1px solid #F4F4F4;
    }

    &.active {
      .mob-nav__subcontainer {
        display: block;
        box-shadow: inset 0 -3rem 3rem -3rem rgba(0, 0, 0, .1);
      }
    }
  }

  &__link {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #585857;

    &-icon {
      fill: $primary-color;
    }

    &-text {
      display: block;
      margin-top: .5rem;
    }
  }

  &__subcontainer {
    position: absolute;
    bottom: 100%;
    left: 0;
    right: 0;
    background: $white;
    display: none;
    padding: 2.5rem;
  }

  &__subheader {
    padding: 1.5rem 5rem 1.5rem;
    text-align: center;
    margin-bottom: 2rem;
    position: relative;
  }

  &__subheading {
    font-size: 1.8rem;
    color: #585857;
  }

  &__close {
    position: absolute;
    top: 1rem;
    right: 2.5rem;
    width: 3.5rem;
    height: 3.5rem;
    background: #F8F8F8;
    border: none;

    &-icon {
      fill: $primary-color;
      width: 2rem;
      height: 2rem;

      @include absolute-center;
    }
  }

  &__sublist {}

  &__subitem {
    font-weight: 600;
    font-size: 1.6rem;

    &:not(:first-child) {
      border-top: 1px solid #F8F8F8;
    }
  }

  &__sublink {
    color: #585857;
    display: block;
    padding: 1.5rem 2rem;
    position: relative;

    &:hover,
    &:focus {
      color: $primary-color;
    }

    &::after {
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg width='6' height='9' viewBox='0 0 6 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.13025 4.50002C5.13025 4.66132 5.06867 4.8226 4.94575 4.94558L1.07591 8.81537C0.829744 9.06154 0.430623 9.06154 0.184552 8.81537C-0.0615175 8.5693 -0.0615175 8.17026 0.184552 7.92407L3.6088 4.50002L0.184672 1.07596C-0.0613983 0.829789 -0.0613983 0.430787 0.184672 0.184737C0.430742 -0.0615521 0.829863 -0.0615521 1.07603 0.184737L4.94587 4.05447C5.06881 4.17751 5.13025 4.33879 5.13025 4.50002Z' fill='%23D1D1D1'/%3E%3C/svg%3E%0A");
      width: 1.2rem;
      height: 1.2rem;
      background-size: contain;
      position: absolute;
      right: 2rem;
      top: 50%;
      transform: translateY(-50%);
      background-repeat: no-repeat;
    }
  }
}

.swiper-container {
  overflow: visible;

  @media (min-width: $xl) {
    overflow: hidden;
  }
}

.js-mob-carousel,
.js-mob-brands-carousel,
.js-mob-history-carousel,
.js-mob-comp-carousel {
  &.swiper-container {
    overflow: visible;
  }
}

.js-mob-carousel,
.js-mob-comp-carousel {
  .row {
    box-sizing: border-box;
    width: auto;
  }

  @media (max-width: $md--mw) {
    .row {
      flex-wrap: nowrap;
      margin: 0;

      > [class*="col-"] {
        padding: 0;
        margin: 0;
      }
    }
  }

  .direction {
    @media (max-width: $sm--mw) {
      display: flex;
      padding: 0 2rem;
      flex-wrap: wrap;

      > * {
        max-width: 100%;
        flex: 0 0 100%;
        height: auto;
      }

      &__preview {
        order: 2;
        position: static;
        display: block;
      }
    }
  }
}

.js-mob-brands-carousel,
.js-mob-partners-carousel {
  .row {
    box-sizing: border-box;
    width: auto;
  }

  @media (max-width: $lg--mw) {
    .row {
      flex-wrap: nowrap;
      margin: 0;

      > [class*="col-"] {
        padding: 0;
        margin: 0;
      }
    }
  }
}

.has-lines .section__controls .swiper-button-prev,
.has-lines .section__controls .swiper-button-next {
  //bottom: 0;
}

.js-brands-carousel-pagination,
.js-team-carousel-pagination,
.js-informers-carousel-pagination,
.js-industry-carousel-pagination,
.js-solution-carousel-pagination,
.js-mob-carousel-pagination,
.js-mob-carousel-brands-pagination,
.js-mob-carousel-history-pagination,
.js-mob-carousel-comp-pagination,
.js-mob-carousel-partners-pagination {
  @media (min-width: $sm) {
    display: none;
  }
}

.section__header--desc .section__heading {
  margin-bottom: 4rem;
}

.projects__list.has-dots .case {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}

.industry-section {
  .swiper-container {
    overflow: visible;
  }
  
  .swiper-slide {
    opacity: .5;
    
    &.swiper-slide-active {
      opacity: 1;
    }
  }
}

.cases-section, .team-section {
  .swiper-container {
    overflow: visible;
  }
  
  .swiper-slide {
    opacity: .5;
    
    &.swiper-slide-active {
      opacity: 1;
    }
    
    &.swiper-slide-next {
      opacity: 1;
    }
  }
}

.news-section, .team-section {
  .swiper-container {
    overflow: visible;
  }
  
  .swiper-slide {
    opacity: .5;
    
    &.swiper-slide-active {
      opacity: 1;
    }
    
    &.swiper-slide-next {
      opacity: 1;
    }
    
    &.swiper-slide-next + .swiper-slide {
      opacity: 1;
    }
  }
}

.partners-section {
  overflow: visible;
}
