.pagination-wrap {
  text-align: center;
}

.pagination {
  position: relative;
  padding: 0 4.25rem;
  display: inline-block;
  font-size: 1.8rem;
  font-weight: 500;

  &__prev,
  &__next,
  &__first,
  &__last {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 1.5rem;
    height: 1.5rem;
    margin-top: -1px;
  }

  &__prev {
    left: 2.25rem;
  }

  &__next {
    right: 2.25rem;
  }

  &__first {
    left: 0;
  }

  &__last {
    right: 0;
  }

  &__icon {
    width: 100%;
    height: 100%;
    fill: $gray;
    vertical-align: top;

    &:hover {
      fill: $primary-color;
    }
  }

  &__list {
    list-style: none;
    margin: 0;
  }

  &__item {
    font-size: 1.4rem;
    text-align: center;
    color: #cdcdcd;
    display: inline-block;
    margin: 0 .1rem;
  }

  &__link {
    color: #cdcdcd;
    padding: 1rem;
    display: block;
    line-height: 1;
    transition: all .3s;

    &:hover,
    &.active {
      color: $primary-color;
    }

    &.active {
      cursor: pointer;
    }
  }
}