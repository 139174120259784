.news-section {
  &__main {
    position: relative;

    &.has-dots {
      &::after {
        content: '';
        width: 30rem;
        height: 30rem;
        background: url('../images/dots.svg') no-repeat center;
        position: absolute;
        right: 100%;
        top: 100%;
        transform: translate(50%, 10%) rotate(-90deg);
        z-index: 1;
      }
    }
  }

  .swiper-container {
    margin: 0 -1.5rem;
  }

  .swiper-slide {
    height: auto;
    padding: 1.5rem;
  }

  &__footer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.news {
  $self: &;

  background: $white;
  border: 1px solid #EEEEEE;
  box-shadow: 0 .8rem 2rem rgba($black, 0.05);
  color: #585857;
  font-size: 1.6rem;
  height: 100%;
  display: flex;
  flex-direction: column;

  &:hover {
    #{$self}__img {
      transform: translate(-50%, -50%) scale(1.05);
    }
  }
  
  &__date {
    display: -webkit-inline-flex;
    display: -moz-inline-flex;
    display: -ms-inline-flex;
    display: -o-inline-flex;
    display: inline-flex;
    margin-bottom: 1rem;
    color: #888888;
  }
  
  &-pagination {
    $self: &;
    
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center;
    
    &__item {
      margin: 0 2.5px;
      
      &:first-child {
        #{$self}__link {
          background: #fff;
          box-shadow: 0 0.5rem 3rem rgba(0,0,0,.1);
          
          &:hover {
            background: #5EBA99;
            color: #fff;
            
            .icon {
              fill: #fff;
            }
          }
        }
      }
      
      &:last-child {
        #{$self}__link {
          background: #fff;
          box-shadow: 0 0.5rem 3rem rgba(0,0,0,.1);
          
          &:hover {
            background: #5EBA99;
            color: #fff;
            
            .icon {
              fill: #fff;
            }
          }
        }
      }
      
      &.is-active {
        #{$self}__link {
          background: #5EBA99;
          color: #fff;
        }
      }
    }
    
    &__link {
      display: -webkit-inline-flex;
      display: -moz-inline-flex;
      display: -ms-inline-flex;
      display: -o-inline-flex;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      font-size: 16px;
      line-height: 100%;
      color: #585857;
      border: none;
      background: #F8F8F8;
      position: relative;
      width: 5.7rem;
      height: 5.7rem;
      transition: all .3s;
      
      &:hover {
        background: #5EBA99;
        color: #fff;
      }
    }
  }

  &__img-wrap {
    display: block;
    overflow: hidden;
    position: relative;

    &::before {
      content: '';
      display: block;
      padding-top: 62%;
    }
  }

  &__img {
    display: block;
    min-height: 100%;
    width: 100%;
    object-position: center;
    object-fit: contain;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: all .3s;
    backface-visibility: hidden;
  }

  &__main {
    padding: 2.5rem 2.5rem 1.5rem;
  }

  &__heading {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
    min-height: 5.5rem;

    &-link {
      color: #585857;
      transition: all .3s;
      display: inline-block;

      &:hover {
        color: $primary-color;
        text-decoration: underline;
      }
    }
  }

  &__excerpt {
    margin: 0;
  }

  &__footer {
    border-top: 1px solid #e6e6e6;
    padding: 2.5rem;
    margin-top: auto;
  }

  &__more {
    font-weight: bold;
    font-size: 1.5rem;
    color: $primary-color;
    display: flex;
    align-items: center;

    &:hover {
      text-decoration: underline;
      color: $primary-color;
    }

    &-icon {
      stroke: $primary-color;
      width: 1.5rem;
      height: 1.5rem;
      margin-left: auto;
    }
  }
}

.news-crop-section {
  &__heading {
    margin: 0;
    font-weight: bold;
    font-size: 3.6rem;
    color: #58595B;
  }

  .swiper-container {
    margin: 0 -1.5rem;
  }

  .swiper-slide {
    padding: 0 1.5rem;
  }
}

.news-crop {
  $self: &;

  display: block;

  &:hover {
    #{$self}__img {
      filter: blur(.5rem);
    }
  }

  &__main {
    position: relative;
    overflow: hidden;
    margin: 0;

    &::before {
      content: '';
      display: block;
      padding-top: 50%;
    }

    &::after {
      content: '';
      background: linear-gradient(0deg, #364D58 0%, rgba(56, 77, 88, 0) 100%);
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }

  &__img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transition: all .3s;
    margin-bottom: 1.5rem;
  }

  &__heading {
    color: $white;
    font-weight: bold;
    font-size: 1.8rem;
    height: 7.4rem;
    overflow: hidden;
    position: absolute;
    left: 0;
    padding: 0 3rem;
    bottom: 2.5rem;
    max-width: 40rem;
    z-index: 3;
  }
}

.news-page .category {
  margin: 0;
}