.tech-consume-section {
  &__heading {
    margin: 0;
    font-weight: bold;
    font-size: 3.6rem;
    color: #585857;
    line-height: 1.4;
  }

  &__main {
    position: relative;

    &.has-dots {
      &::after {
        content: '';
        width: 46rem;
        height: 23rem;
        background: url(../images/dots-wide.svg) no-repeat center / contain;
        position: absolute;
        left: 100%;
        top: 100%;
        transform: translate(-45%, 2%);
        z-index: 1;
      }
    }
  }

  &__targets {
    display: flex;
    margin: 0 -1.5rem;
    flex-wrap: wrap;
  }

  &__target {
    padding: 0 1.5rem;
    flex: 0 0 100%;
    margin-bottom: 1rem;

    @media (min-width: $sm) {
      flex: 0 0 50%;
      margin-bottom: 0;
    }

    &:not(:nth-child(-n+2)) {
      margin-top: 2rem;
    }
  }
}

.tech-section-demo {
  &__main {
    background: $white;
    border: 1px solid #EEEEEE;
    box-shadow: 0 .8rem 2rem rgba($black, 0.05);
    margin: 0;
    padding: 2rem .5rem 2rem 2rem;
    display: flex;
    align-items: flex-end;
    position: relative;
    flex-wrap: wrap;

    @media (min-width: $md) {
      flex-wrap: nowrap;
      margin: 0 0 0 2.5rem;
    }

    &.has-dots {
      &::after {
        content: '';
        width: 30rem;
        height: 30rem;
        background: url('../images/dots.svg') no-repeat center;
        position: absolute;
        left: 100%;
        top: 0%;
        transform: translate(-50%, -35%) rotate(-90deg);
        z-index: -1;
      }
    }

    + .news-crop-section {
      margin-top: 6rem;
    }
  }

  &__steps {
    margin: 0;
    flex: 0 0 100%;
    max-width: 100%;

    @media (min-width: $md) {
      margin: 0 0 0 -4.6rem;
      flex: 0 0 60%;
      max-width: 60%;
    }
  }

  &__img-wrap {
    flex: 0 0 100%;
    max-width: 100%;
    position: relative;

    @media (min-width: $md) {
      flex: 0 0 40%;
      max-width: 40%;
    }
  }

  &__img {
    max-width: 100%;
    height: auto;
    display: block;
    position: initial;
    bottom: 0;
    width: 100%;
    right: 0;

    @media (min-width: $md) {
      position: absolute;
    }
  }
}

.demo-steps {
  counter-reset: demo-step;
  list-style: none;

  &__item {
    counter-increment: demo-step;
    color: #455B66;
    position: relative;
    min-height: 5rem;
    padding: 0 0 0 6.7rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:not(:last-child) {
      margin-bottom: 3rem;
    }

    &::before {
      content: counter(demo-step, decimal-leading-zero);
      width: 5rem;
      height: 5rem;
      line-height: 5rem;
      font-weight: bold;
      font-size: 3rem;
      position: absolute;
      left: 0;
      top: 0;
      background: $primary-color;
      text-align: center;
      color: $white;
    }

    &:not(:last-child) {
      &::after {
        content: '';
        width: 0;
        border-left: 2px solid $primary-color;
        top: 5.5rem;
        bottom: -2.5rem;
        left: 2.5rem;
        position: absolute;
      }
    }
  }
}