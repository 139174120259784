.direction {
  $self: &;

  background: $white;
  border: 1px solid #EEEEEE;
  box-shadow: 0 .8rem 2rem rgba($black, 0.05);
  position: relative;
  padding: 0 0 0 40%;
  height: 100%;
  overflow: hidden;

  @media (min-width: $md) {
    padding: 0 0 0 50%;
  }

  &--value {
    padding-left: 19rem;

    #{$self}__preview {
      max-width: 19rem;
      overflow: visible;

      &-img {
        //margin: auto 0;
        //max-width: none;
      }
    }

    #{$self}__heading {
      margin-bottom: 1rem;
    }
  }

  &__preview {
    position: absolute;
    left: 0;
    bottom: 0;
    overflow: hidden;
    height: 100%;
    display: flex;
    max-width: 35%;

    @media (min-width: $md) {
      max-width: 50%;
    }

    &-img {
      max-width: 100%;
      display: block;
      height: auto;
      margin-top: auto;
    }
  }

  &__main {
    padding: 3rem 1rem 3rem 0;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 2;
    height: 100%;
  }

  &__heading {
    margin-bottom: 2rem;
    font-size: 2.4rem;
    color: #585857;
    font-weight: bold;
    line-height: 1.3;
  }

  &__excerpt {
    margin-bottom: 0;
  }

  &__list {
    margin-bottom: 3rem;
  }

  &__bottom {
    margin-top: auto;
  }

  &__more {
    font-size: 1.5rem;
    font-weight: bold;
    color: $primary-color;

    &:hover {
      color: $primary-color;
      text-decoration: underline;
    }

    &-text {
      vertical-align: middle;
    }

    &-icon {
      stroke: $primary-color;
      width: 1.3rem;
      height: 1.3rem;
      margin: 0 0 0 .5rem;
    }
  }

  &--offset {
    @media (min-width: $md) {
      padding: 0 0 0 42%;

      #{$self}__preview {
        max-width: 42%;
      }
    }
  }
}

.direction-section {
  .row > [class*="col-"] {
    margin: 1.2rem 0;

    @media (min-width: $md) {
      &:not(:nth-child(-n+2)) {
        margin-top: 2.5rem;
        margin-bottom: 0;
      }
    }
  }
}
