.footer {
  color: #999999;
  font-size: 1.5rem;
  margin-top: 4rem;
  border-top: 1px solid #eee;

  @media (min-width: $md) {
    border-top: none;
    margin-top: 0;
  }

  &__main {
    padding: 5.5rem 0 3rem;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -.5rem;

    > * {
      padding: 0 .5rem;
    }

    @media (min-width: $md) {
      flex-wrap: nowrap;
      justify-content: space-between;
      margin: 0 -1.5rem;

      > * {
        padding: 0 1.5rem;
      }
    }
  }

  &__navigation {
    order: 3;
    margin: 3rem 0 0;
    width: 100%;

    @media (min-width: $md) {
      width: auto;
      padding: 0 0 0 4rem;
      margin: 0;
      order: 0;
    }

    @media (min-width: $xl) {
      padding: 0 0 0 6rem;

      .col-xl-3 {
        max-width: 29%;
        flex: 0 0 29%;

        + .col-xl-5 {
          max-width: 40%;
          flex: 0 0 36%;
        }
      }
    }
  }

  &__company {
    flex: 0 0 50%;
    max-width: 50%;

    @media (min-width: $md) {
      flex: initial;
      max-width: none;
    }
  }

  &__contacts {
    flex: 0 0 50%;
    max-width: 50%;
    text-align: center;

    @media (min-width: $md) {
      text-align: left;
      flex: initial;
      max-width: none;
    }
  }

  &__logo {
    margin: 0 0 2rem;

    .logo__img {
      margin: 0 auto;

      @media (min-width: $md) {
        margin: 0;
      }
    }
  }

  &-nav {
    &__heading {
      font-size: 1.6rem;
      margin-bottom: 1.5rem;
      font-weight: 600;
      line-height: 1.2;

      &-link {
        color: #58595B;
        transition: all .3s;

        &:hover {
          color: $primary-color;
        }
      }
    }

    &__list {
      margin: 0;
      padding: 0;
    }

    &__item {
      font-size: 1.6rem;
      max-width: 24rem;

      &:not(:first-child) {
        margin-top: 1rem;
      }
    }

    &__link {
      color: #999999;
      transition: all .3s;

      &:hover {
        color: #666666;
      }
    }

    &__more {
      display: inline-block;
      color: $primary-color;
      margin-top: 1rem;

      &:hover {
        color: $primary-color;
        text-decoration: underline;
      }
    }

    + .footer-nav {
      margin-top: 2rem;
    }
  }

  &__contacts {
    @media (min-width: $xl) {
      margin: -.5rem 0 0 -1rem;
    }

    &-list {
      margin: 0 0 2rem;
      font-size: 1.6rem;
      line-height: 1;
    }

    &-item {
      white-space: nowrap;

      &:not(:first-child) {
        margin-top: 1.5rem;
      }
    }

    &-link {
      color: #58595B;
      transition: all .3s;

      &:hover {
        color: $primary-color;
      }

      &[href^="tel:"] {
        font-weight: 600;
        font-size: 2rem;

        span {
          text-decoration: underline;
        }

        &:hover {
          span {
            text-decoration: none;
          }
        }
      }
    }

    &-text {
      vertical-align: middle;
    }

    &-icon {
      fill: none;
      stroke-width: 2px;
      width: 2.2rem;
      height: 2.2rem;
      stroke: $primary-color;
      margin: 0 1rem 0 0;
    }
  }

  &__messagers {
    padding: 0 0 0 2.5rem;

    img {
      max-width: 2rem;
    }
  }

  &__bottom {
    background: #F8F8F8;
    padding: 2rem 0;
    display: none;

    @media (min-width: $md) {
      display: block;
    }

    &-main {
      display: flex;
    }

    &-aside {
      margin-left: auto;
      display: flex;
      align-items: center;
    }
  }

  &__privacy {
    margin: 0 2rem 0 0;

    &-item {
      display: inline-block;
      margin: 0 2rem;
      vertical-align: middle;
    }

    &-link {
      color: #999;

      &:hover {
        color: #999;
        text-decoration: underline;
      }
    }
  }

  .soc {
    white-space: nowrap;
    text-align: center;

    &__icon {
      height: auto;
    }
  }
}

.copy {
  font-size: 1.5rem;

  @media (min-width: $md) {
    order: -1;
  }
}

.dev {
  &__logo {
    display: block;
    max-width: 100%;
    height: auto;
  }
}

.is-hidden {
  display: none;
}

@media (max-width: $md--mw) {
  .footer-nav {
    $self: &;

    &.opened {
      padding-bottom: 2rem;

      #{$self}__list {
        &-main {
          display: block;
        }
      }

      #{$self}__heading {
        &-link {
          &::after {
            transform: rotate(180deg);
          }
        }
      }
    }

    &__heading {
      margin-bottom: 2rem;
    }

    &__heading-link {
      display: block;
      padding: 2rem 0 0;
      border-top: 1px solid #eee;
      position: relative;

      &::after {
        content: "";
        width: 1.4rem;
        height: 1.4rem;
        background-image: url("data:image/svg+xml,%3Csvg width='11' height='6' viewBox='0 0 11 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 1L5.5 5L1 1' stroke='%2358595B' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        position: absolute;
        right: 1.5rem;
        top: 2rem;
        transition: all .3s;
      }
    }

    &__list {
      &-main {
        display: none;

        #{$self}__heading {
          font-weight: 400;
          margin: 0;

          &-link {
            border: none;
            padding-top: 0;

            &::after {
              display: none;
            }
          }
        }
      }
    }
  }
}
