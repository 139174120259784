.solution-include {
  display: flex;
  flex-direction: column;
  padding: 2.5rem 3rem;
  min-height: 100%;
  background: $white;
  border: 1px solid #eee;
  box-shadow: 0 0.8rem 2rem rgba($black, 0.05);

  &__heading {
    margin: 0 0 1rem;
    color: #455B66;
    font-size: 2.6rem;
    font-weight: 700;

    &--small {
      font-size: 2.4rem;
    }
  }

  &__img-wrap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center'';
    flex: 1 1 auto;
  }

  &__img {
    width: 100%;
  }
}
