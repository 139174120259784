.btn {
  $self: &;

  display: inline-block;
  padding: 1.8rem 3rem;
  font-size: 1.6rem;
  border: .2rem solid transparent;
  background: transparent;
  text-align: center;
  cursor: pointer;
  color: $btn-color;
  line-height: 1;
  outline: none;
  vertical-align: middle;
  user-select: none;
  white-space: nowrap;
  border-radius: $btn-radius;
  position: relative;
  font-weight: bold;
  width: auto;
  transition: all .3s;

  &[disabled] {
    color: #333;
    cursor: default;
    pointer-events: none;
    opacity: .7;
    filter: grayscale(50%);
  }

  &:hover {
    color: $btn-color;
  }

  /* --- Buttons Types --- */
  &--primary {
    color: $btn-primary-color;
    background: $btn-primary-bg;

    &:hover,
    &:focus {
      background: $btn-primary-hover;
    }

    &:active {
      background: $btn-primary-active;
    }

    &[disabled] {
      background-color: $btn-disabled-bg;
    }

    &-reverse {
      color: $btn-primary-bg;
      border-color: $btn-primary-bg;

      &:hover,
      &:focus {
        color: $btn-primary-color;
        background: $btn-primary-bg;
        border-color: $btn-primary-bg;
      }

      &:active {
        background: $btn-primary-active;
        border-color: $btn-primary-active;
      }

      &[disabled] {
        border-color: $btn-disabled-bg;
      }
    }
  }

  &--secondary {
    color: $btn-secondary-color;
    background: $btn-secondary-bg;

    &:hover,
    &:focus {
      background: $btn-secondary-hover;
    }

    &:active {
      background: $btn-primary-active;
    }

    &[disabled] {
      background-color: $btn-disabled-bg;
    }

    &-reverse {
      color: $btn-secondary-bg;
      background: $btn-secondary-color;
      border-color: $btn-secondary-bg;

      &:hover,
      &:focus {
        color: $btn-secondary-color;
        background: $btn-secondary-bg;
        border-color: $btn-secondary-bg;
      }

      &:active {
        background: $btn-secondary-active;
        border-color: $btn-secondary-active;
      }

      &[disabled] {
        border-color: $btn-disabled-bg;
      }
    }
  }

  &--tertiary {
    color: $btn-tertiary-color;
    background: $btn-tertiary-bg;

    &:hover,
    &:focus {
      background: $btn-tertiary-hover;
    }

    &:active {
      background: $btn-tertiary-active;
    }

    &[disabled] {
      background-color: $btn-disabled-bg;
    }

    &-reverse {
      color: $btn-tertiary-bg;
      background: $btn-tertiary-color;
      border-color: $btn-tertiary-bg;

      &:hover,
      &:focus {
        color: $btn-tertiary-color;
        background: $btn-tertiary-bg;
        border-color: $btn-tertiary-bg;
      }

      &:active {
        background: $btn-tertiary-active;
        border-color: $btn-tertiary-active;
      }

      &[disabled] {
        border-color: $btn-disabled-bg;
      }
    }
  }

  &--gray {
    background: $btn-gray-bg;
    color: $btn-gray-color;

    &:hover,
    &:focus {
      background: $btn-gray-hover;
    }

    &:active {
      background: $btn-gray-active;
    }

    &-reverse {
      color: $btn-gray-bg;
      background: transparent;
      border-color: $btn-gray-bg;

      &:hover,
      &:focus {
        background: $btn-gray-bg;
        border-color: $btn-gray-bg;
      }

      &:active {
        background: $btn-gray-active;
      }
    }
  }
  
  // Btn Statusses
  &--danger {
    background: $btn-danger-bg;
    color: $btn-danger-color;

    &:hover {
      background: $btn-danger-hover;
    }

    &-reverse {
      color: $btn-danger-bg;
      // background: $btn-danger-color;
      border-color: $btn-danger-bg;

      &:hover {
        color: $btn-danger-color;
        background: $btn-danger-bg;
        border-color: $btn-danger-bg;
      }
    }
  }

  &--grad {
    background: $btn-grad-bg;
    color: $btn-grad-color;
    transition: none;
    background-origin: border-box;

    &:hover {
      background: $btn-grad-hover;
    }
  }

  /* --- Buttons Animated --- */
  &--animated {
    z-index: 1;
    
    &::after {
      content: "";
      display: inline-block;
      width: 100%;
      height: 100%;
      border-radius: $btn-radius;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      transition: all .3s;
      background: inherit;
    }

    &:hover {
      &::after {
        transform: scaleX(1.4) scaleY(1.6);
        opacity: 0;
      }
    }
  }
  
  // --- Buttons Types

  // Buttons Sizes
  &--sm {
    font-size: 1.4rem;
    padding: .8rem 1rem;
  }

  &--lg {
    font-size: 1.8rem;
    padding: 1.5rem 3rem;
  }

  // Icons
  &__icon {
    fill: $btn-icon-fill-color;
    color: $btn-icon-fill-color;
    max-width: 1.3rem;
    max-height: 1.3rem;
    display: inline-block;
    vertical-align: middle;

    & + #{$self}__text {
      margin-left: .5rem;
    }
  }

  &__text {
    & + #{$self}__icon {
      margin-left: .5rem;
    }
  }

  &--block {
    width: 100%;
    display: block;
  }
}