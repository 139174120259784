.docs-section {
  &__body {

    @media (min-width: $xl) {
      display: flex;
      align-items: center;
    }
  }

  &__main {
    @media (min-width: $xl) {
      flex: 0 0 55%;
      max-width: 55%;
      position: relative;
      z-index: 2;
    }
  }

  &__aside {

    @media (min-width: $xl) {
      flex: 0 0 45%;
      max-width: 45%;
      position: relative;
      z-index: 1;
    }

    .section__controls {
      position: absolute;
      bottom: 0;
      right: 0;

      @media (min-width: $xl) {
        top: 5.5rem;
        right: 0;
        bottom: auto;
        z-index: 3;
      }

      .swiper-button-prev,
      .swiper-button-next {
        position: relative;

        @media (min-width: $sm) {
          top: 0;
        }
      }

      .swiper-button-prev {
        left: 0;
      }
    }
  }

  &__desc {
    @media (min-width: $md) {
      align-items: center;
    }

    &-img-wrap {
      flex: 0 0 13rem;
      max-width: 13rem;
      margin-right: 2rem;
    }

    &-content {
      flex: 1 1 auto;
      min-width: 0;
      font-size: 1.8rem;
      font-weight: 500;

      p {
        margin-bottom: 1.2rem;

        &:last-child {
          margin-bottom: 0;
        }
      }

      a {
        color: $primary-color;
      }
    }
  }

  &__slider {
    @media (min-width: $xl) {
      margin-left: -15%;
    }

    &.swiper-container {
      overflow: visible;
    }
  }

  &__slide {
    &.swiper-slide-active {
      .docs-section__item {
        opacity: 1;

        @media (min-width: $xl) {
          box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        }
      }
    }

    &.swiper-slide-prev,
    &.swiper-slide-next {
      .docs-section__item {
        @media (min-width: $xl) {
          opacity: 0.3;
        }
      }
    }
  }

  &__heading {
    margin-bottom: 3.5rem;
  }

  &__subtitle {
    margin: 0 0 2rem;
    font-size: 1.8rem;
    font-weight: 600;
  }

  &__item {
    display: block;
    transition: opacity 0.5s;

    @media (min-width: $xl) {
      opacity: 0;
    }
  }

  &__img {
    width: 100%;
    height: auto;
  }
}
