body {
  font-weight: $font-base-weight;
  font-size: $font-base-size;
  font-family: $font-base-stack;
  line-height: $base-line-height;
  color: $font-base-color;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-weight: $headings-weight;
  font-family: $headings-fonts-stack;
  line-height: $headings-line-height;
  color: $headings-color;
  text-align: center;

  @media (min-width: $sm) {
    text-align: left;
  }

  span,
  small {
    color: $dark--medium;
    font-size: smaller;
    font-weight: 400;
  }
}

h1, .h1 {
  font-size: 3rem;
  margin-bottom: 2rem;
}

h2, .h2 {
  font-size: 2.6rem;
  margin-bottom: 1.75rem;
}

h3, .h3 {
  font-size: 2.2rem;
  margin-bottom: 1.5rem;
}

h4, .h4 {
  font-size: 2rem;
  margin-bottom: 1.4rem;
}

h5, .h5 {
  font-size: 1.6rem;
  margin-bottom: 1.2rem;
}

h6, .h6 {
  font-size: 1.4rem;
  margin-bottom: 1rem;
}

.text-primary {
  color: $primary-color;
}

.main-heading {
  margin-bottom: 2.5rem;
  font-family: $headings-fonts-stack;
  font-size: 3.8rem;
  font-weight: bold;
  line-height: 1.1;
  color: #455B66;

  @media (min-width: $sm) {
    font-size: 4rem;
  }

  @media (min-width: $md) {
    font-size: 5.3rem;
  }

  &--average {
    font-size: 4.5rem;
  }

  &--small {
    font-size: 3.3rem;
  }
}

.secondary-heading {
  position: relative;
  margin-bottom: 3rem;
  font-family: $headings-fonts-stack;
  color: #585857;
  font-size: 3.6rem;
}

.tertiary-heading {
  text-transform: uppercase;
  font-size: 2.5rem;
  font-family: $headings-fonts-stack;
  color: $primary-color;
  position: relative;
  line-height: 1.2;
  padding-bottom: 1.5rem;
  margin-bottom: 2rem;

  @media (min-width: $md) {
    font-size: 4rem;
  }
}

p {}

p.lead {
  font-size: 1.8rem;
}

p.small {
  font-size: 1.4rem;
}

.list {
  font-weight: 500;

  $self: &;

  &--grid {
    column-count: 2;
    column-gap: 40px;
  }

  &--inline {
    #{$self}__item {
      margin-right: 2.7rem;
      display: inline-block;
      vertical-align: top;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &--square {
    #{$self}__item {
      padding-left: 3.5rem;

      &::before {
        top: 0.2rem;
        width: 2rem;
        height: 2rem;
        background-image: url('data:image/svg+xml,%3Csvg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Crect x="5.45459" y="5.45459" width="9.09091" height="9.09091" fill="%234EBCC2"/%3E%3Crect width="20" height="20" fill="%234EBCC2" fill-opacity="0.2"/%3E%3C/svg%3E%0A');
      }
    }
  }

  &--small {
    font-size: 1.4rem;
    line-height: 1.3;

    #{$self} {
      &__item {
        padding-left: 2.3rem;

        &::before {
          top: 0.1rem;
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    }
  }

  &__item {
    position: relative;
    margin-bottom: 1rem;
    padding-left: 3rem;

    &:last-child {
      margin-bottom: 0;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0.5rem;
      left: 0;
      width: 1.7rem;
      height: 1.3rem;
      background-image: url('data:image/svg+xml,%3Csvg width="17" height="13" viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M15.3333 2L6.16667 11.1667L2 7" stroke="%2357BE85" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/%3E%3C/svg%3E%0A');
      background-size: 100% auto;
    }

    a {
      color: $primary-color;
      font-weight: 700;
      text-decoration: underline;
      transition: color 0.25s;
    }
  }
}

/* ---------- Post Typography ---------- */
.post-entry {
  margin: 0;

  > *:not(:last-child) {
    margin: 0 0 2.5rem;
  }

  a:not([class]) {
    &:link {
      color: $link-color-normal;
    }

    &:visited {
      color: $link-color-visited;
    }

    &:hover {
      color: $link-color-hover;
      text-decoration: underline;
    }

    &:active {
      color: mix(black, $link-color-hover, 25%);
    }
  }

  p {}

  strong {
    font-weight: bold;
  }

  ul {
    list-style: none;

    li {
      padding-left: 3rem;
      position: relative;

      &:not(:first-child) {
        margin-top: 2rem;
      }

      &::before {
        content: "·";
        color: #b4b4b4;
        font-size: 2rem;
        margin-right: 1.5rem;
        position: absolute;
        left: 0;
        top: 0;
        line-height: 1.3;
      }
    }
  }

  ol {
    counter-reset: item-counter;

    > li {
      counter-increment: item-counter;
    }

    li {
      padding-left: 3.5rem;
      position: relative;

      &:not(:first-child) {
        margin-top: 2rem;
      }

      &::before {
        content: counter(item-counter) ". ";
        font-size: 2rem;
        color: $primary-color;
        font-family: $headings-fonts-stack;
        position: absolute;
        left: 0;
        top: 0;
      }
    }

    ul {
      color: #9a9a9a;
      margin-top: 2rem;

      li {
        padding-left: 4rem;
        font-weight: 400;

        &::before {
          content: "·";
          background: transparent;
          color: $primary-color;
          position: absolute;
          left: 0;
          top: 0;
        }
      }
    }
  }

  dl {
    dt {}

    dd {}
  }

  blockquote {
    font-style: italic;
    color: $dark--medium;
  }

  q {}

  cite {}

  figure {}

  figcaption {
    font-weight: 500;
    color: #b8b8b8;
    font-size: 1.2rem;
    padding: 1.5rem 0;
    border-bottom: 1px dotted #b8b8b8;
  }

  img {
    max-width: 100%;
    display: block;
    height: auto;
  }

  .float-left {
    float: left;
    margin: 1.5rem 1.5rem 1.5rem 0;
  }

  .float-right {
    float: right;
    margin: 1.5rem 0 1.5rem 1.5rem;
  }

  &__preview {
    text-align: center;
    max-width: 82rem;
    margin-left: auto;
    margin-right: auto;
  }

  &__summary {
    padding-bottom: 2rem;
    padding-top: 2rem;
    border-bottom: 1px dotted #e7e7e7;
    margin: 0;

    @media (min-width: $md) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
/* ---------- Post Typography ---------- */
