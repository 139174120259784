.informer {
  padding: 3rem 2rem 2rem 3rem;
  border: 1px solid #EEEEEE;
  background: $white;
  box-shadow: 0 .8rem 2rem rgba($black, 0.05);

  &__heading {
    margin-bottom: 2rem;
    font-size: 3.3rem;
    color: #585857;
    font-weight: bold;
  }

  &__countries {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1rem;
  }

  &__country {
    margin: 1.1rem 0;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    flex: 0 0 50%;
    max-width: 50%;
    
    @media (min-width: $sm) {
      flex: 0 0 35%;
      max-width: 35%;
    }

    &:nth-child(even) {
      flex: 0 0 50%;
      max-width: 50%;
      
      @media (min-width: $sm) {
        flex: 0 0 65%;
        max-width: 65%;
      }
    }

    &-flag {
      border: 1px solid #E7E7E7;
    }

    &-name {
      font-size: 1.6rem;
      color: #585857;
      font-weight: 500;
      white-space: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0 0 0 1.5rem;
      
      @media (min-width: $sm) {
        white-space: nowrap;
      }
    }
  }

  .service-target {
    display: flex;
    border: none;
    box-shadow: none;
    align-items: center;
    padding: 0;
    height: auto;
    min-height: 0;
    background: none;

    &__icon-wrap {
      position: relative;
      width: 7.1rem;
      height: 7.1rem;
      margin: 0;
      flex: none;
    }

    &__icon {
      width: 4.8rem;
      height: 4.8rem;
    }

    &__excerpt {
      font-weight: 500;
      font-size: 1.6rem;
      padding-left: 1.5rem;
      line-height: 1.6;

      strong {
        font-weight: bold;
        font-size: 1.8rem;
        color: $secondary-color;
      }
    }
  }

  [class*="col-"] {
    margin: 1.4rem 0 1.5rem;
  }

  &__compact {
    .service-target {
      &__icon-wrap {
        width: 5.3rem;
        height: 5.3rem;
      }

      &__icon {
        width: 3.6rem;
        height: 3.6rem;
      }
    }

    [class*="col-"] {
      margin: .6rem 0;
    }
  }
}

.about-section,
.actual-section {
  position: relative;

  .swiper-container {
    overflow: visible;
  }

  .swiper-slide {
    &:not(.swiper-slide-active):not(.swiper-slide-next) {
      opacity: .5;
    }
  }
}

.actual-section {
  &__footer {
    margin-top: 4.5rem;
  }

  &__heading {
    margin: 0 0 3.5rem;
    color: #455B66;
    font-size: 33px;
    font-weight: 700;
  }
}
