.js-dropdown {
  position: relative;
}

.js-dropdown-toggle {}

.js-dropdown-body {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: $white;
  z-index: $dropdownZIndex;
  opacity: 0;
  visibility: hidden;

  @at-root .js-dropdown.is-opened {
    .js-dropdown-toggle {
      z-index: $dropdownAboveZIndex;
    }

    .js-dropdown-body {
      visibility: visible;
      animation: .2s fadeInUp forwards;
    }
  }
}