.industry {
  $self: &;

  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  box-shadow: 0 .8rem 2rem rgba(black, 0.05);
  padding: 2.5rem;
  position: relative;

  @media (min-width: $sm) {
    // padding: 4.5rem 52rem 4.5rem 4.5rem;
    padding: 4.5rem 57rem 4.5rem 4.5rem;
  }

  &__heading {
    font-weight: bold;
    font-size: 3.5rem;
    color: #58595B;
    margin-bottom: 1.5rem;
    line-height: 1.25;
  }

  &__list {
    margin-bottom: 3rem;
    font-size: 1.8rem;

    .item {
      font-weight: 500;

      &::before {
        top: .5rem;
      }
    }
  }

  &__preview {
    margin-top: 2rem;
    // max-width: 50rem;
    max-width: 45rem;

    @media (min-width: $sm) {
      margin-top: 0;
      position: absolute;
      right: 1.5rem;
      bottom: 1.5rem;
    }
  }

  &__img {
    max-width: 100%;
    display: block;
    height: auto;
  }

  &__btn {
    min-width: 22rem;
  }

  &--wide {
    @media (min-width: $sm) {
      // padding: 4.5rem 49rem 4.5rem 4.5rem;
      padding: 4.5rem 54rem 4.5rem 4.5rem;

      #{$self}__preview {
        // max-width: 51rem;
        max-width: 46rem;
        bottom: 4.5rem;
      }
    }
  }

  &--middle {
    @media (min-width: $sm) {
      #{$self}__preview {
        bottom: 50%;
        transform: translateY(50%);
      }
    }
  }
}

.industry-section {
  @media (min-width: $lg) {
    padding-bottom: 7rem;
  }

  &__main {
    padding-top: 3rem;

    @media (min-width: $sm) {
      padding-top: 0;
    }
  }

  .category {
    max-width: 86rem;
    margin-bottom: 3rem;
    padding: 0 1.5rem;
  }

  .swiper-container {
    margin: -3rem -1.5rem 0;
  }

  .swiper-slide {
    padding: 0 1.5rem 2rem;
  }
}