// ****************
// * Fixed Footer *
// ****************
.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;

  &__main {
    flex-grow: 1;
    overflow: hidden;
  }

  &__footer {}

  .content {
    background: $white;
    border-radius: .4rem;
    box-shadow: 0 .3rem 1.2rem rgba($black, 0.08);
    padding: 3.5rem;
  }
}