.soc {
  $self: &;

  margin: 0;
  padding: 0;

  &__item {
    display: inline-block;
    vertical-align: middle;
    text-indent: -9999px;

    &:not(:first-child) {
      margin-left: 1.5rem;
    }
  }
  
  &__link {
    width: 2.2rem;
    height: 2.2rem;
    display: block;
    transition: all .3s;
    text-align: center;
    position: relative;

    &:hover {
      opacity: .8;
    }
  }

  &__icon {
    width: 2.5rem;
    height: 2.5rem;
    transition: all .3s;

    @include absolute-center;
  }
}

.messagers {
  text-align: center;

  &__caption {
    color: $primary-color;
    font-weight: bold;
    margin-bottom: 1.2rem;
    line-height: 1.2;
    font-size: 1.7rem;
  }

  &__list {
    white-space: nowrap;
    margin: 0;
  }

  &__item {
    display: inline-block;
    margin: 0 1rem;
  }

  &__link {
    transition: all .3s;

    &:hover {
      opacity: .8;
    }
  }
}