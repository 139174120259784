.agency {
  display: block;
  color: #585857;
  font-size: 2.4rem;
  font-weight: 600;
  text-align: center;
  transition: 0.25s;

  &:hover {
    color: $primary-color;

    .agency__flag {
      border-color: $primary-color;
    }
  }

  &__flag {
    margin: 0 auto;
    display: block;
    width: 15.3rem;
    height: 10.2rem;
    border: 2px solid transparent;
    box-shadow: 0 0.8rem 2rem rgba($black, 0.05);
    transition: border 0.25s;
  }

  &__img {
    width: 100%;
    height: 100%;
  }

  &__title {
    margin-top: 1.2rem;
    display: block;
  }
}

.agency-section {
  &__row {
    flex-wrap: nowrap;
    overflow: hidden;
    overflow-x: auto;

    @media (min-width: $md) {
      justify-content: space-between;
      overflow: hidden;
    }
  }
}
