.brand {
  $self: &;

  position: relative;
  background-color: $white;
  border-bottom: 1px solid #EEEEEE;
  box-shadow: 0 .8rem 2rem rgba($black, 0.05);

  @media (min-width: $md) {
    height: 14.5rem;
    overflow: hidden;
  }

  &:not(.brand--default):hover {
    overflow: visible;
    border-bottom-color: transparent;
    box-shadow: none;

    #{$self}__img {
      filter: none;
    }

    #{$self}__info {
      opacity: 1;
      visibility: visible;
    }
  }

  &--default {
    height: auto;
    min-height: 100%;
    overflow: visible;

    #{$self}__main {
      position: relative;
      border: 0;
      box-shadow: none;
    }

    #{$self}__img {
      filter: grayscale(0) invert(0);
    }

    #{$self}__info {
      margin-top: 0.5rem;
      font-size: 1.8rem;
      opacity: 1;
      visibility: visible;
      
      @media (min-width: $md) {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &__main {
    background-color: $white;
    border: 1px solid #EEEEEE;
    box-shadow: 0 .8rem 2rem rgba($black, 0.05);
    padding: 2.5rem 1.5rem;
    margin: 0;

    @media (min-width: $md) {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
    }
  }

  &__img-wrap {
    position: relative;
    display: block;

    &::after {
      content: '';
      display: block;
      padding-top: 35%;
    }

    @media (min-width: $md) {
      min-height: 10rem;

      &::after {
        display: none;
      }
    }
  }

  &__img {
    transition: all .3s;
    filter: grayscale(100%) invert(.5);
    max-width: 100%;
    max-height: 100%;

    @include absolute-center;
  }

  &__info {
    margin-top: 2rem;
    font-weight: 500;
    font-size: 1.6rem;
    color: #585857;
    display: block;
    opacity: 1;
    visibility: visible;

    @media (min-width: $md) {
      display: block;
      opacity: 1;
      visibility: visible;
    }

    &--center {
      text-align: center;
    }
  }

  &__link {
    color: $primary-color;
    font-size: 2rem;
    font-weight: 600;
    text-decoration: underline;
    transition: color 0.25s;

    &:hover {
      color: $secondary-color;
    }
  }
}

.partners-section {
  max-width: 1350px;
  margin: 0 auto;
  overflow: hidden;

  &__main {
    .swiper-container {
      margin: 0 -1rem;
      overflow: visible;
    }

    .swiper-slide {
      @media (max-width: $lg--mw) {
        padding: 0 0 2rem;
      }
    }
  }
}
