.service-target {
  $self: &;

  background: $white;
  box-shadow: 0 .8rem 2rem rgba($black, 0.05);
  padding: 3rem;
  position: relative;
  height: 100%;
  z-index: 2;
  min-height: 15rem;
  padding-left: 6.5rem;
  margin-left: 3.5rem;
  border: 1px solid #EEEEEE;

  @media (min-width: $md) {
    padding: 3rem;
    margin: 0;
    border: none;
  }

  &__img {
    display: block;
    width: 90%;
    margin-top: 2rem;
  }

  &__item {
    padding-left: 1rem;
    position: relative;
    font-weight: 600;
    line-height: 1.8;
    margin-bottom: 2rem;

    &:before {
      content: '';
      width: 5px;
      height: 5px;
      background: #4EBCC2;
      border-radius: 50%;
      position: absolute;
      top: 8px;
      left: -5px;
    }
  }

  &__icon-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $white;
    font-size: 3.4rem;
    font-weight: 700;
    background: $primary-color;
    margin: 0;
    position: absolute;
    width: 7.9rem;
    height: 7.9rem;
    left: -3.5rem;
    top: auto;

    @media (min-width: $md) {
      margin: -3rem -3rem 2.5rem;
      position: relative;
      width: 11rem;
      height: 11rem;
      left: auto;
      top: auto;
    }

    &--secondary {
      background: $secondary-color;
    }
  }

  &__icon {
    width: 6.6rem;
    height: 6.6rem;
    fill: $white;
    object-fit: contain;

    @include absolute-center;
  }

  &__body {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__heading {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
    max-width: 42rem;
  }

  &__subtitle {
    margin: 0 0 10px;
    color: $secondary-color;
    font-size: 1.6rem;
    font-weight: 700;
  }

  &__bottom {
    margin-top: auto;
  }

  &__more {
    color: $primary-color;
    font-weight: bold;
    font-size: 1.5rem;

    &:hover {
      color: $primary-color;
      text-decoration: underline;
    }

    .icon {
      stroke: $primary-color;
      width: 1.3rem;
      height: 1.3rem;
    }
  }

  &__link-wrap {
    margin-top: 2rem;
  }

  &__link {
    font-weight: bold;
    font-size: 1.5rem;
    color: $primary-color;
    display: inline-flex;
    align-items: center;

    &:hover {
      text-decoration: underline;
      color: $primary-color;
    }

    &-icon {
      position: relative;
      top: 0.1rem;
      margin-left: 1rem;
      stroke: $primary-color;
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  &__excerpt {
    font-weight: 600;
    margin: 0;
    line-height: 1.8;
    font-size: 1.8rem;

    @media (min-width: $md) {
      font-size: 1.6rem;
    }
  }

  &__desc {
    font-weight: 500;
    margin: 0;
  }

  &__group {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__list {
    margin-bottom: 0;
  }

  &__value {
    color: $white;
    font-weight: bold;
    font-size: 3.5rem;
    white-space: nowrap;

    @include absolute-center;
  }

  &--wide {
    border: 1px solid #EEEEEE;
    margin-left: 3.5rem;
    padding-left: 7rem;
    padding-right: 1.5rem;

    @media (min-width: $md) {
      padding-left: 9.5rem;
    }

    #{$self}__icon-wrap {
      position: absolute;
      left: -3.5rem;
      top: 2.7rem;
      margin: 0;
    }
  }

  &--actual {
    margin-left: 4rem;
    padding-left: 6rem;
    padding-right: 1rem;
    background-color: $white;

    #{$self}__icon-wrap {
      position: absolute;
      top: 2.5rem;
      left: -4rem;
      margin: 0;
      width: 8rem;
      height: 7.7rem;
    }

    #{$self}__icon {
      width: 70%;
    }

    #{$self}__heading {
      max-width: 100%;
      font-size: 18px;
			
			@media (min-width: $md) {
				font-size: 24px;
			}
    }
  }

  &--category {
    margin-left: 4rem;
    padding-left: 7rem;
    padding-bottom: 4.5rem;
    background-color: $white;

    #{$self}__icon-wrap {
      position: absolute;
      top: 2.5rem;
      left: -4rem;
      margin: 0;
      width: 9rem;
      height: 9rem;
    }

    #{$self}__heading {
      max-width: 100%;
      font-size: 2.4rem;
    }

    #{$self}__desc {
      font-weight: 400;
    }
  }

  &--stat {
    display: flex;
    align-items: center;
    margin-left: 4.4rem;
    padding: 2.5rem 3rem 2.5rem 7.5rem;
    min-height: 12rem;
    background-color: $white;

    #{$self}__icon-wrap {
      position: absolute;
      top: 1.5rem;
      left: -4.4rem;
      margin: 0;
      width: 9rem;
      height: 9rem;
    }

    #{$self}__icon {
      width: 70%;
    }

    #{$self}__excerpt {
      font-weight: 500;
      line-height: 1.5;
    }
  }

  &--average {
    padding-left: 6.5rem;

    #{$self}__icon-wrap {
      width: 7.9rem;
      height: 7.9rem;
    }

    #{$self}__icon {
      width: 5.5rem;
      height: 5.5rem;
    }
  }

  &--medium {
    padding-left: 7.5rem;
    padding-right: 1.5rem;

    #{$self}__icon-wrap {
      width: 9rem;
      height: 9rem;
    }

    #{$self}__icon {
      width: 6rem;
      height: 6rem;
    }

    #{$self}__excerpt {
      font-weight: 500;
      font-size: 1.6rem;
    }
  }

  &--small {
    display: flex;
    align-items: center;
    margin-left: 3.5rem;
    padding: 1.5rem 2rem 1.5rem 5.5rem;
    min-height: 10.1rem;
    background-color: $white;

    #{$self}__icon-wrap {
      top: 1.3rem;
      left: -3.5rem;
      width: 7.1rem;
      height: 6.9rem;
      font-size: 2.6rem;
    }

    #{$self}__icon {
      width: 4.5rem;
      height: 4.5rem;
    }

    #{$self}__excerpt {
      line-height: 1.5;

      &--normal {
        font-size: 1.3rem;
        font-weight: 500;
      }
    }
  }
}

.service-section-targets {
  .col-md-3 {
    margin-bottom: 2rem;

    @media (min-width: $md) {
      margin-bottom: 0;
    }
  }

  &__main {
    position: relative;

    .col-md-4 {
      margin-bottom: 2rem;

      @media (min-width: $md) {
        margin-bottom: 0;
      }
    }

    @media (min-width: $lg) {
      &.has-dots::after {
        content: '';
        width: 30rem;
        height: 30rem;
        background: url('../images/dots.svg') no-repeat center;
        position: absolute;
        left: 0;
        top: 100%;
        transform: translate(-20rem, -40%);
        z-index: -1;
      }

      &.has-dots-secondary::after {
        content: '';
        width: 59rem;
        height: 30rem;
        background: url('../images/dots-wide.svg') no-repeat center;
        position: absolute;
        left: 0;
        top: 100%;
        transform: translate(-47%, -45%);
        z-index: 1;
      }

      &.has-dots-sm {
        &::after {
          width: 42rem;
          height: 21rem;
          background-size: contain;
          transform: translate(-40%, -25%);
        }
      }
    }

    .tech-start {
      margin: 1rem 0 3rem;
    }

    .row.has-gutter [class*="col-"] {
      margin-bottom: 3rem;
    }
  }

  + .service-result {
    margin-top: 6.5rem;
  }

  .col {
    margin-bottom: 2.5rem;
  }
}

.service-section-missions {
  .row:not(.not-gutter) [class*="col-"] {
    margin-bottom: 3rem;
  }

  &__main {
    position: relative;

    @media (min-width: $xl) {
      &.has-dots::after {
        content: '';
        width: 59rem;
        height: 30rem;
        background: url('../images/dots-wide.svg') no-repeat center;
        position: absolute;
        left: 100%;
        top: 100%;
        transform: translate(-55%, -30%);
        z-index: -1;
      }
    }
  }
}

.service-section-audit,
.actual-section {
  &__main {
    position: relative;

    &.has-dots::after {
      content: '';
      width: 59rem;
      height: 30rem;
      background: url('../images/dots-wide.svg') no-repeat center;
      position: absolute;
      left: 100%;
      top: 100%;
      transform: translate(-55%, -30%);
      z-index: -1;
    }
  }
}

.section {
  &__advantages {
    margin-bottom: 5rem;

    &:last-child {
      margin-bottom: 0;
    }

    &-heading {
      text-align: center;
    }
  }

  &__content {
    line-height: 1.3;
    text-align: center;
  }

  &__title {
    margin: 0 0 1.8rem;
    font-size: 2.4rem;
    font-weight: 700;
  }

  &__subtitle {
    font-size: 1.8rem;
    font-weight: 600;

    a {
      color: $secondary-color;
      font-weight: 700;
      text-decoration: underline;
      transition: color 0.25s;

      &:hover {
        color: $primary-color;
      }
    }
  }
}

.service-section {
  &__col {
    margin-bottom: 5rem;
		flex-basis: auto;
  }
}

.list {
  &__item {
    font-weight: 600;
    ul {
      padding-left: 2rem;
      margin-top: 1rem;

      li {
        position: relative;
        line-height: 1.8;
        margin-bottom: 1rem;
        font-weight: normal;

        &:before {
          content: '';
          width: 5px;
          height: 5px;
          background: #4EBCC2;
          border-radius: 50%;
          position: absolute;
          top: 8px;
          left: -15px;
        }
      }
    }
  }
}

.section--small {
  .col-sm-3 {
    margin-bottom: 2rem;
  }

  .col-sm-4 {
    margin-bottom: 2rem;
  }

  .col-sm-6 {
    margin-bottom: 2rem;
  }

  .col-sm-12 {
    margin-bottom: 2rem;
  }

  .col-md-3 {
    margin-bottom: 2rem;
  }

  .col-md-4 {
    margin-bottom: 2rem;
  }

  .col-md-6 {
    margin-bottom: 2rem;
  }

  .col-md-12 {
    margin-bottom: 2rem;
  }
}

.actual-section__footer {
  .col-sm-3 {
    margin-bottom: 2rem;
  }

  .col-sm-4 {
    margin-bottom: 2rem;
  }

  .col-sm-6 {
    margin-bottom: 2rem;
  }

  .col-sm-12 {
    margin-bottom: 2rem;
  }

  .col-md-3 {
    margin-bottom: 2rem;
  }

  .col-md-4 {
    margin-bottom: 2rem;
  }

  .col-md-6 {
    margin-bottom: 2rem;
  }

  .col-md-12 {
    margin-bottom: 2rem;
  }
}

.service-target--small .service-target__excerpt--normal {
  font-size: 1.8rem;

  @media (min-width: $md) {
    font-size: 1.6rem;
  }
}

.js-mob-carousel .swiper-slide:not(.swiper-slide-active):not(.swiper-slide-next) {
  opacity: 1;
}