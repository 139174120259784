.projects {
  padding: 9rem 0;

  &__header {
    position: relative;

    &.has-dots {
      &::after {
        content: '';
        width: 30rem;
        height: 30rem;
        background: url('../images/dots-2.svg') no-repeat center;
        position: absolute;
        right: 0;
        top: 0;
        transform: translate(50%, 0);
        z-index: 1;
      }
    }
  }

  &__heading {
    color: #455B66;
  }

  &__controls {
    display: flex;
    margin: 0 -1.5rem 5rem;
    flex-wrap: wrap;
  }

  &__control {
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 1.5rem;
    margin-bottom: 1.5rem;

    @media (min-width: $md) {
      flex: 0 0 33.333%;
      max-width: 33.333%;
      margin-bottom: 0;
    }
  }

  &__list {
    &.has-dots {
      position: relative;

      &::after {
        content: '';
        height: 58rem;
        width: 30rem;
        background: url('../images/dots-vertical.svg') no-repeat center;
        position: absolute;
        right: 100%;
        top: 50%;
        transform: translate(-.5rem, -50%);
        z-index: 1;
      }

      .case {
        z-index: 2;
      }
    }
  }

  &__section {
    position: relative;

    &.has-dots {
      &::after {
        content: '';
        height: 58rem;
        width: 30rem;
        background: url('../images/dots-vertical.svg') no-repeat center;
        position: absolute;
        left: 100%;
        top: 0;
        transform: translate(-30%, 0);
        z-index: 1;
      }

      .case {
        z-index: 2;
      }
    }

    &:not(:last-child) {
      margin-bottom: 2rem;
    }

    &-column {
      margin-bottom: 1rem;

      @media (min-width: $md) {
        margin-bottom: 3rem;
      }
    }

    &-heading {
      color: #455B66;
    }
  }
}

.filter-control {
  $self: &;

  &.is-opened {
    #{$self}__field {
      box-shadow: 0 .8rem 2rem rgba($black, 0.05);

      &::after {
        transform: translateY(-50%) rotate(180deg);
      }
    }

    #{$self}__body {
      box-shadow: 0 .8rem 2rem rgba($black, 0.05);
    }
  }

  &__field {
    padding: 1.5rem 4rem 1.5rem 2.5rem;
    background: $white;
    border: 2px solid #5EBA99;
    box-shadow: 0 .8rem 2rem rgba($black, 0.05);
    position: relative;
    transition: all .3s;

    &::after {
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg width='12' height='7' viewBox='0 0 12 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11 1L6 6L1 1' stroke='%235EBA99' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      right: 2.5rem;
      transition: all .3s;
      top: 50%;
      transform: translateY(-50%);
      width: 1.2rem;
      height: 1.2rem;
    }
  }

  &__text {
    font-weight: 500;
    color: #455B66;
    display: block;

    @include truncate-text;
  }

  &__body {
    padding: 1.5rem 1.2rem 3rem;
  }

  &__list {
    margin: 0;
  }

  &__item {
    transition: all .3s;
    padding: .7rem 1.3rem;

    &:not(:first-child) {
      margin-top: .5rem;
    }

    &:hover {
      background: #F6F7F8;

      .checkbox-label {
        .checkbox-label__input:not(:checked) + .checkbox-label__main {
          &::before {
            border-color: #BDBFC7;
          }
        }

        &__main {
          color: #222B45;
        }
      }
    }

    .checkbox-label {
      &__main {
        font-weight: 500;
        color: #9095A2;
        font-size: 1.6rem;

        &::before {
          border: 2px solid #DEDFE3;
          transition: all .3s;
        }
      }
    }
  }
}
