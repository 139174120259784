.tech-start {
  counter-reset: order;
  padding: 0 .3rem;
  display: none;

  @media (min-width: $sm) {
    display: flex;
  }

  &__item {
    flex: 1;
    position: relative;
    counter-increment: order;

    &:not(:last-child) {
      border-top: .4rem solid $white;
    }

    &::before {
      content: '';
      width: 1.3rem;
      height: 1.3rem;
      background: $secondary-color;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(.9rem, -50%);
      box-shadow: 0 0 0 1rem rgba(78, 188, 194, .2);
      border-radius: 50%;
    }

    &::after {
      content: counter(order);
      font-size: 5.6rem;
      font-weight: bold;
      color: #D6ECED;
      position: absolute;
      left: 4.5rem;
      top: 50%;
      transform: translateY(-50%);
    }

    &:nth-child(even) {
      &::before {
        background: $primary-color;
        box-shadow: 0 0 0 .9rem rgba(94, 186, 153, .2);
      }

      &::after {
        color: #D9ECE5;
      }
    }
  }
}

.demo {
  &-steps {
    &__item {
      font-weight: 600;

      ul {
        padding-left: 4rem;
        margin-top: 1rem;
        margin-bottom: -2rem;

        li {
          position: relative;
          line-height: 1.8;
          margin-bottom: 1rem;
          font-weight: normal;

          &:before {
            content: '';
            width: 5px;
            height: 5px;
            background: #4EBCC2;
            border-radius: 50%;
            position: absolute;
            top: 8px;
            left: -15px;
          }
        }
      }
    }
  }
}

.row {
  .tech-section-demo__steps {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.tech-crop {
  display: block;

  &__main {
    position: relative;
    overflow: hidden;
    margin: 0;

    &::before {
      content: '';
      display: block;
      padding-top: 60%;
    }
  }

  &__img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transition: all .3s;
    margin-bottom: 1.5rem;
  }

  &__heading {
    color: $white;
    font-weight: bold;
    font-size: 1.8rem;
    height: 5.4rem;
    overflow: hidden;
    position: absolute;
    left: 0;
    padding: 0 3rem;
    bottom: 2.5rem;
    max-width: 40rem;
    z-index: 3;
  }
}

.tech-section-demo {
  &__slider {
    margin-top: 2rem;
    overflow: hidden;

    @media (min-width: $md) {
      margin-top: -7rem;
    }
  }

  &__footer {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: 1rem;
    position: relative;
    z-index: 1;
    padding-right: 0;
    align-items: center;

    &.is-big {
      margin-top: 1rem;
    }

    @media (min-width: $md) {
      padding-right: 2rem;
      align-items: flex-end;
      margin-top: -7rem;
    }
  }

  &__name {
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 150%;
    color: #455B66;
    margin-top: 1rem;
    display: flex;
    padding-right: 18rem;
  }

  &__nav {
    flex: 0 0 16rem;
    display: flex;
    justify-content: flex-end;
  }

  .tech-crop__main {
    &:before {
      padding-top: 70%;
    }
  }
}