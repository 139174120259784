.js-section-carousel,
.js-cases-carousel,
.js-news-carousel,
.js-promo-carousel,
.js-informers-carousel,
.js-industry-carousel,
.js-brands-carousel {
  visibility: hidden;
  opacity: 0;
  transition: all .3s;

  &.swiper-container-initialized {
    opacity: 1;
    visibility: visible;
  }
}

.swiper-pagination-bullets {
  text-align: center;
  margin-top: 2rem;
  padding: 2rem 0;

  @media (min-width: $md) {
    display: none;
  }

  .swiper-pagination-bullet {
    width: 1.28rem;
    height: 1.28rem;
    background: #EAEAEA;
    display: inline-block;
    margin: 0 0.4rem;
    cursor: pointer;
    position: relative;
    outline: none;

    @media (min-width: $xl) {
      width: 1.6rem;
      height: 1.6rem;
      margin: 0 .7rem;
    }

    &-active {
      cursor: default;
      background-color: $primary-color;
    }
  }
}

.swiper-button-prev,
.swiper-button-next {
  border: none;
  background: $white;
  box-shadow: 0 .5rem 3rem rgba(0, 0, 0, 0.1);
  position: relative;
  width: 5.7rem;
  height: 5.7rem;
  transition: all .3s;

  @media (min-width: $xl) {
    width: 7rem;
    height: 7rem;
  }

  .icon {
    fill: #4B4A4A;
    width: 2rem;
    height: 2rem;

    @media (min-width: $xl) {
      width: 2.5rem;
      height: 2.5rem;
    }

    @include absolute-center;
  }

  &:not(.swiper-button-disabled):hover {
    background: $primary-color;
    box-shadow: 0 1rem 5rem rgba(1, 62, 28, 0.25);

    .icon {
      fill: $white;
    }
  }
}

.swiper-button-next {
  margin-left: 1rem;
}

.swiper-button-disabled {
  filter: grayscale(50%);
  opacity: .5;
  cursor: default;
}
