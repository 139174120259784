.contacts-section {
  &__main {
    position: relative;
    display: none;

    @media (min-width: $lg) {
      max-width: 940px;
      margin: 0 auto;
      display: block;
    }

    @media (min-width: $xl) {
      max-width: 1160px;
      margin: 0 auto;
    }
  }

  &__map {
    display: block;
    width: 100%;

    @media (min-width: $lg) {
      max-width: 940px;
      margin: 0 auto;
    }

    @media (min-width: $xl) {
      max-width: 1160px;
      margin: 0 auto;
    }
  }

  &__hidden {
    display: block;

    @media (min-width: $lg) {
      display: none;
    }
  }

  &__info {
    background: #fff;
    border: 1px solid #5EBA99;
    box-sizing: border-box;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.05);
    padding: 3rem 2rem;
    height: 100%;
  }

  &__col {
    margin-bottom: 2rem;
  }

  &__icon {
    cursor: pointer;
  }

  &__item {
    position: absolute;

    &.is-active {
      .contacts-section__card {
        opacity: 1;
        visibility: visible;
      }
    }

    .contacts-section__card {
      opacity: 0;
      visibility: hidden;
      transition: .3s;
    }

    &:nth-child(1) {
      @media (min-width: $lg) {
        top: 106px;
        right: 395px;
      }

      @media (min-width: $xl) {
        top: 131px;
        right: 492px;
      }
    }

    &:nth-child(2) {
      @media (min-width: $lg) {
        top: 138px;
        right: 285px;
      }

      @media (min-width: $xl) {
        top: 160px;
        right: 346px;
      }
    }

    &:nth-child(3) {
      @media (min-width: $lg) {
        top: 114px;
        right: 450px;
      }

      @media (min-width: $xl) {
        top: 150px;
        right: 560px;
      }
    }

    &:nth-child(4) {
      @media (min-width: $lg) {
        top: 185px;
        right: 277px;
      }

      @media (min-width: $xl) {
        top: 247px;
        right: 346px;
      }
    }

    &:nth-child(5) {
      @media (min-width: $lg) {
        top: 193px;
        left: 127px;
      }

      @media (min-width: $xl) {
        top: 247px;
        left: 160px;
      }
    }

    &:nth-child(6) {
      @media (min-width: $lg) {
        top: 170px;
        right: 395px;
      }

      @media (min-width: $xl) {
        top: 217px;
        right: 491px;
      }
    }
    
    &:nth-child(7) {
      @media (min-width: $lg) {
        top: 209px;
        right: 364px;
      }

      @media (min-width: $xl) {
        top: 266px;
        right: 453px;
      }
    }
  }

  &__card {
    width: 395px;
    height: 250px;
    background-image: url("data:image/svg+xml,%3Csvg width='395' height='250' viewBox='0 0 395 250' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg filter='url(%23filter0_d)'%3E%3Cpath d='M190.69 12H375V72.8481V168.975H157.45H110.286H75.9234L20 222L75.9234 12H190.69Z' fill='white'/%3E%3Cpath d='M190.69 13H374V72.8481V167.975H157.45H110.286H75.9234H75.5246L75.2353 168.249L21.8754 218.844L76.6919 13H190.69Z' stroke='%235EBA99' stroke-width='2'/%3E%3C/g%3E%3Cdefs%3E%3Cfilter id='filter0_d' x='0' y='0' width='395' height='250' filterUnits='userSpaceOnUse' color-interpolation-filters='sRGB'%3E%3CfeFlood flood-opacity='0' result='BackgroundImageFix'/%3E%3CfeColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'/%3E%3CfeOffset dy='8'/%3E%3CfeGaussianBlur stdDeviation='10'/%3E%3CfeColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0'/%3E%3CfeBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow'/%3E%3CfeBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow' result='shape'/%3E%3C/filter%3E%3C/defs%3E%3C/svg%3E%0A");
    padding: 25px 30px 90px 85px;
    position: absolute;
    top: -192px;
    left: 0;
    pointer-events: none;
  }
  
  &__content {
    height: 100%;
    pointer-events: all;
  }

  &__title {
    font-weight: bold;
    font-size: 18px;
    text-align: left;
  }

  &__text {
    font-weight: 600;
    font-size: 14px;
    line-height: 130%;

    a {
      color: #585857;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__name {
    color: #6CB182;
  }
}