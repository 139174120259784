.breadcrumbs {
  font-size: 1.6rem;
  margin: 0 0 5.5rem;
  padding: 0;

  @media (max-width: $md--mw) {
    white-space: nowrap;
    overflow-x: auto;
  }

  &__item {
    display: inline;
    color: #888;
    position: relative;

    &:not(:first-child) {
      padding-left: 2rem;
      margin-left: .5rem;

      &::before {
        content: "";
        background-image: url("data:image/svg+xml,%3Csvg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.82814 3.10041L9.82761 3.09987L6.76597 0.164385C6.53661 -0.055522 6.16562 -0.0547037 5.93731 0.166305C5.70903 0.387284 5.70991 0.744708 5.93927 0.964644L7.99486 2.93548H0.585937C0.262324 2.93548 0 3.18822 0 3.5C0 3.81178 0.262324 4.06452 0.585937 4.06452H7.99483L5.9393 6.03536C5.70994 6.25529 5.70906 6.61272 5.93734 6.8337C6.16565 7.05473 6.53667 7.05549 6.766 6.83561L9.82764 3.90013L9.82817 3.89959C10.0576 3.67892 10.0569 3.32034 9.82814 3.10041Z' fill='%23888888'/%3E%3C/svg%3E%0A");
        width: 1rem;
        height: 1rem;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        left: 0;
        top: .4rem;
      }
    }
  }

  &__link {
    color: #888;

    &:hover {
      color: #666666;
      text-decoration: underline;
    }
  }
}