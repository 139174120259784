.service-result {
  $self: &;

  position: relative;

  &__wrapper {
    display: flex;
    flex-direction: column;
    border: 2px solid $primary-color;

    @media (min-width: $md) {
      flex-direction: row;
      align-items: center;
      border: 0;
    }
  }

  &__main,
  &__preview {
    @media (min-width: $md) {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }

  &__main {
    margin: 0 -6rem 0 -4rem;
    z-index: 3;
    position: relative;

    &-info {
      padding: 0 0 2rem;
      background: $white;

      @media (min-width: $sm) {
        padding: 0 2rem 2rem;
      }

      @media (min-width: $md) {
        padding: 4.5rem 3rem;
        border: 1px solid #EEEEEE;
        box-shadow: 0px 8px 20px rgba($black, 0.05);
      }

      > *:not(:last-child):not(.main-heading) {
        margin-bottom: 1.5rem;
      }

      strong {
        //font-size: 2rem;
        //color: #455B66;
        font-weight: 600;
      }

      ul {
        //font-size: 1.5rem;
      }

      span {
        font-weight: 500;
      }
    }
  }

  &__heading {
    margin-bottom: 1rem;
    font-size: 3.24rem;

    @media (min-width: $sm) {
      font-size: 4rem;
    }

    @media (min-width: $md) {
      font-size: 5.3rem;
    }
  }

  &__lead {
    font-size: 2rem;

    @media (min-width: $lg) {
      font-size: 2.4rem;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__preview {
    order: -1;
    position: relative;

    @media (min-width: $md) {
      margin-left: -4rem;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, #FFFFFF 7.41%, rgba(255, 255, 255, 0) 100%);

      @media (min-width: $md) {
        display: none;
      }
    }
  }

  &__video {
    position: relative;
    display: block;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 2;
      width: 10rem;
      height: 10rem;
      background: url(../images/youtube.png) center no-repeat;
      transform: translate(-50%, -50%);
    }
  }

  &__img {
    max-width: 100%;
    display: block;
    width: 100%;
    height: auto;
  }

  &.has-dots {
    &::after {
      content: '';
      width: 59rem;
      height: 30rem;
      background: url(../images/dots-wide.svg) no-repeat center;
      position: absolute;
      left: 100%;
      top: 100%;
      transform: translate(-55%, -30%);
      z-index: -1;
    }
  }

  &.has-dots-square {
    &::after {
      content: '';
      width: 30rem;
      height: 30rem;
      background: url(../images/dots.svg) no-repeat center;
      position: absolute;
      left: 100%;
      top: 100%;
      transform: translate(-25%, -65%);
      z-index: 1;
    }

    .service-result__preview {
      position: relative;
      z-index: 2;
    }
  }

  &.has-dots-secondary {
    &::after {
      content: '';
      width: 30rem;
      height: 30rem;
      background: url(../images/dots.svg) no-repeat center;
      position: absolute;
      right: 100%;
      top: 100%;
      transform: translate(50%, -65%) rotate(-90deg);
      z-index: 1;
    }

    .service-result__preview {
      z-index: 2;
      position: relative;
    }
  }

  &--bordered {
    #{$self}__preview {
      margin: 0;
    }

    #{$self}__main {
      margin: 0;
      //margin: 0 0 2rem 0;

      @media (min-width: $md) {
        margin: 0 -13rem 3rem 0;

        &::before {
          content: '';
          left: -1.5rem;
          right: 10rem;
          top: 1.5rem;
          bottom: -1.5rem;
          position: absolute;
          height: 100%;
          z-index: -1;
          background: #f8f8f8;
        }
      }
    }
  }

  &--bordered-accent {
    #{$self}__main {
      &::before {
        background: $primary-color;
      }

      &-info {
        border-color: $primary-color;
      }
    }
  }

  &--reversed {
    #{$self}__main {
      margin: 0;

      @media (min-width: $sm) {
        margin: 0 0 3rem -16rem;
      }
    }

    &#{$self}--bordered {
      #{$self}__main {
        &::before {
          left: 13rem;
          right: -1.5rem;
        }
      }
    }
  }
}

.service-result--bordered {
  .service-result__main, .service-result__preview {
    max-width: calc(100% + 16rem);
  }
}

.service-result-section {
  .service-result__main, .service-result__preview {
    max-width: calc(100% + 16rem);
  }

  .service-result__main-info {
    padding: 4rem;
  }
}

.service-section {
  &__main {
    margin-bottom: 3rem;
  }
}