.case {
  $self: &;

  position: relative;
  overflow: hidden;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;

  &::before {
    content: '';
    display: block;
    padding-top: 100%;

    @media (min-width: $sm) {
      padding-top: 52%;
    }
  }

  &::after {
    content: '';
    background: linear-gradient(0deg, #364D58 0%, rgba(56, 77, 88, 0) 100%);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  &:hover {
    #{$self}__preview {
      filter: blur(1.5rem);
    }
  }

  &__main {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 2.5rem;
    z-index: 2;
    display: flex;
    flex-direction: column;
    color: $white;

    &::before {
      content: '';
      background: linear-gradient(180deg, rgba(54, 77, 88, 0.8) 7.91%, rgba(56, 77, 88, 0) 80.58%);
      position: absolute;
      left: 0;
      top: 0;
      height: 50%;
      width: 100%;
      z-index: -1;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $white;
  }
  
  &__type {
    font-size: 1.8rem;
    font-weight: 600;

    @include truncate-text;

    &-link {
      text-decoration: underline;
      color: $white;

      &:hover {
        color: $white;
        text-decoration: none;
      }
    }

    &-icon {
      width: 2.7rem;
      height: 2.7rem;
      margin: 0 1.5rem 0 0;
    }
  }

  &__body {
    margin-top: auto;
  }

  &__heading {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1rem;
    line-height: 1.1;

    @media (min-width: $sm) {
      font-size: 3rem;
    }

    &-link {
      color: $white;

      &:hover {
        text-decoration: underline;
        color: $white;
      }
    }
  }

  &__desc {
    font-size: 1.5rem;
    font-weight: 500;
    color: $white;
    margin: 0 0 1.5rem;
    max-height: 5rem;
    overflow: hidden;
  }

  &__preview {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transition: all .3s;
    margin-bottom: 1.5rem;
  }

  &__footer {
    padding: 1.5rem 0 0;
    position: relative;

    &::before {
      content: '';
      left: -2.5rem;
      right: -2.5rem;
      border-top: 1px solid $white;
      position: absolute;
      top: 0;
      opacity: .1;
    }
  }

  &__more {
    color: $white;
    padding: 0;
    font-size: 1.5rem;

    &:hover {
      color: $white;
      text-decoration: underline;
    }

    &-text {}

    &-icon {}
  }
}

.cases-section {
  &__area {
    position: relative;

    &.has-dots {
      &::before {
        content: '';
        width: 58rem;
        height: 29.5rem;
        background: url('../images/dots-wide.svg') no-repeat center;
        position: absolute;
        left: 0;
        bottom: 0;
        transform: translate(-16rem, -.5rem);
      }
    }
  }

  &__summary {
    text-align: center;
    margin-top: 5rem;

    .btn {
      padding-left: 1.6rem;
      padding-right: 1.6rem;
    }
  }
}