.team {
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  background: $white;
  border: 1px solid #EEEEEE;
  overflow: hidden;
  min-height: 230px;
  box-shadow: 0 0.8rem 2rem rgba($black, 0.05);
  
  &-card {
    position: relative;
    overflow: hidden;
    
    &__img {
      position: relative;
      padding-bottom: 85%;
      
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    
    &:hover {
      .team-card__content {
        bottom: 0;
      }
    }
    
    &__content {
      background: #5EBA99;
      transition: .3s;
      position: absolute;
      left: 0;
      right: 0;
      bottom: -63px;
    }
    
    &__body {
      padding: 20px 25px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    }
    
    &__link {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      padding: 20px 25px;
      font-style: normal;
      font-weight: bold;
      font-size: 15px;
      text-transform: capitalize;
      color: #FFFFFF;
      
      &:hover {
        color: #fff;
      }
    }
    
    &__name {
      display: block;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 150%;
      color: #FFFFFF;
      margin-bottom: 7px;
    }
    
    &__position {
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      color: #FFFFFF;
    }
  }

  @media (min-width: $xl) {
    flex-direction: row;
  }

  &__main {
    position: relative;
    z-index: 2;
    padding: 2rem;

    @media (min-width: $xl) {
      padding: 2.5rem 13rem 2.5rem 2.5rem;
    }

    &.is-active {
      padding: 2rem;

      @media (min-width: $xl) {
        padding: 2.5rem;
      }
      ~ .team__img-cover {

        @media (min-width: $xl) {
          right: -50%;
        }
      }

      // .team__excerpt {
      //   max-width: 100%;
      // }
      // .team__hidden {
      //   max-width: 100%;
      // }
    }
  }

  &__hidden {
    transition: height .25s;
    overflow: hidden;
    font-size: 1.4rem;
    max-width: 90%;

    &:not(.show) {
      display: none;
    }
  }

  &__post {
    margin: 0 0 0.2rem;
    color: #9D9D9D;
    font-size: 1.4rem;
    font-weight: 600;
  }

  &__name {
    margin: 0 0 0.8rem;
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.5;
  }

  &__excerpt {
    margin: 0;
    max-width: 90%;
    font-size: 1.4rem;
    line-height: 1.3;
    margin-bottom: 1rem;
  }

  &__img-cover {
    position: relative;
    height: 300px;
    overflow: hidden;
    transition: .3s;
    padding: 1px;

    @media (min-width: $xl) {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      width: 22rem;
      height: auto;
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      margin: -1px;
      bottom: 0;
      background: linear-gradient(0deg, #FFFFFF 7.41%, rgba(255, 255, 255, 0) 100%);

      @media (min-width: $xl) {
        display: none;
      }
    }
  }

  &__more {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 100%;
    color: #5EBA99;
    display: inline-flex;
    align-items: center;

    &:hover {
      color: #5EBA99;
    }

    &.is-hide {
      &:after {
        transform: rotate(-90deg);
      }
    }

    &:after {
      content: '';
      display: inline-flex;
      margin-left: 1rem;
      width: 12px;
      height: 8px;
      background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.00001 3.99994L10.3333 3.99994' stroke='%235EBA99' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.22223 1L10.3333 4L7.22223 7' stroke='%235EBA99' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    }
  }

  &__img {
    margin-top: auto;
    display: block;
    width: 100%;
    height: auto;
  }
}

.team-detail {
  &__img {
    img {
      display: block;
      width: 100%;
      box-shadow: 2rem 2rem 0 0 #5eba99; 
    }
  }
  
  &__content {
    padding-left: 15px;
    
    @media (min-width: $md) {
      padding-left: 4rem;
    }
  }
  
  &__title {
    font-style: normal;
    font-weight: bold;
    font-size: 3rem;
    line-height: 120%;
    color: #585857;
    text-align: left;
    
    @media (min-width: $md) {
      font-size: 4rem;
    }
  }
  
  &__position {
    font-style: normal;
    font-weight: 500;
    font-size: 2rem;
    line-height: 150%;
    color: #9D9D9D;
    display: -webkit-inline-flex;
    display: -moz-inline-flex;
    display: -ms-inline-flex;
    display: -o-inline-flex;
    display: inline-flex;
    margin-bottom: 2rem;
  }
  
  &__text {
    font-style: normal;
    font-weight: normal;
    font-size: 1.8rem;
    line-height: 180%;
    color: #455B66;
  }
  
  &__link {
    font-style: normal;
    font-weight: bold;
    font-size: 1.8rem;
    line-height: 100%;
    text-decoration-line: underline;
    color: #5EBA99;
    display: -webkit-inline-flex;
    display: -moz-inline-flex;
    display: -ms-inline-flex;
    display: -o-inline-flex;
    display: inline-flex;
    align-items: center;
    margin-top: 2rem;
    
    &:before {
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.9998 4.00006L1.6665 4.00006' stroke='%235EBA99' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M4.77761 7L1.6665 4L4.77761 1' stroke='%235EBA99' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      width: 12px;
      height: 8px;
      margin-right: 1rem;
    }
  }
}