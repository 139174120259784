.work-steps {
  counter-reset: step;
  background: $white;

  $self: &;

  @at-root .section--gray & {
    background: #f8f8f8;
  }

  &--large {
    #{$self}__item {
      margin-right: 4rem;

      &:not(:first-child) {
        margin-left: 0;

        @media (min-width: $sm) {
          margin-left: 2rem;
        }
      }
    }
  }

  &--wrap {
    #{$self}__list {
      flex-wrap: wrap;
    }
  }

  &__list {
    margin: 0 0 2rem;
    display: flex;
    overflow: hidden;
    flex-wrap: wrap;

    @media (min-width: $sm) {
      flex-wrap: nowrap;
      box-shadow: -2rem .8rem 2rem rgba($black, 0.05);
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__item {
    // flex: 0 0 25%;
    // max-width: 25%;
    flex: 0 0 100%;
    position: relative;
    min-height: 12.5rem;
    margin-bottom: 4.5rem;

    @media (min-width: $sm) {
      flex: 1;
      margin-bottom: 0;
      margin-bottom: 1rem;
    }

    &:not(:last-child) {
      margin-right: 0;
      
      @media (min-width: $sm) {
        margin-right: 1rem;
      }
    }

    &::before,
    &::after {
      @media (min-width: $sm) {
        content: '';
        position: absolute;
        width: 100%;
        height: 50%;
        left: 0;
        border-right: .5rem solid $primary-color;
        box-shadow: 1rem 0 0 0 #f8f8f8;
        background: $white;
      }
    }

    &::before {
      content: '';
      clip-path: polygon(50% 30%, 0 0, 100% 0);
      background: #5EBA99;
      top: 100%;
      left: 0;
      right: 0;
      width: 100%;
      position: absolute;
      height: 100px;
      
      @media (min-width: $sm) {
        transform: skew(20deg) translateX(-4rem);
        top: 0;
        transform-origin: top left;
        clip-path: none;
        background: transparent;
        left: auto;
        right: auto;
        height: 50%;
        width: 100%;
      }
    }

    &::after {
      content: '';
      clip-path: polygon(50% 30%, 0 0, 100% 0);
      background: #fff;
      top: calc(100% - 1px);
      left: 15px;
      right: 15px;
      width: calc(100% - 30px);
      position: absolute;
      height: 90px;
      
      @media (min-width: $sm) {
        transform: skew(-20deg) translateX(-4rem);
        bottom: 0;
        transform-origin: bottom left;
        clip-path: none;
        background: transparent;
        left: 0;
        right: auto;
        height: 50%;
        width: 100%;
        top: auto;
      }
    }

    &:last-child {
      &::before,
      &::after {
        box-shadow: none;
      }
    }

    &:nth-child(even) {
      &::before,
      &::after {
        border-right-color: $secondary-color;
      }
    }
  }

  &__step {
    counter-increment: step;
    position: relative;
    z-index: 2;
    padding: 2.5rem 3.5rem 2.5rem 2.5rem;
    height: 100%;

    &::before {
      content: counter(step, decimal-leading-zero);
      position: absolute;
      right: 4.5rem;
      bottom: .5rem;
      color: #EEEEEE;
      font-size: 8rem;
      line-height: 1;
      font-weight: bold;
      z-index: -1;
    }
  }

  &__desc {
    max-width: 25rem;
    margin: 0;
    font-size: 1.5rem;
    font-weight: 600;
    color: #585857;
    position: relative;
    z-index: 2;
  }

  &__heading {
    margin: 0 0 1.4rem;
    font-size: 2rem;
    font-weight: 700;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__ul-list {
    font-weight: 400;
    line-height: 1.4;
  }
}

.work-steps-section {
  &__main {
    position: relative;

    &.has-dots::after {
      content: '';
      width: 30rem;
      height: 30rem;
      background: url('../images/dots.svg') no-repeat center;
      position: absolute;
      left: 0;
      top: 100%;
      transform: translate(-20rem, -40%);
      z-index: 1;
    }

    &.has-dots-secondary::after {
      content: '';
      width: 30rem;
      height: 30rem;
      background: url('../images/dots.svg') no-repeat center;
      position: absolute;
      left: 100%;
      top: 100%;
      transform: translate(-30%, -90%) rotate(-90deg);
      z-index: 1;
    }
  }

  &__steps {
    position: relative;
    z-index: 2;
  }
}
