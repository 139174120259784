/* Aka Helpers
------------------------------------------ */
.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.mb-small {
  margin-bottom: 2rem;
}

.mb-medium {
  margin-bottom: 2rem;

  @media (min-width: $md) {
    margin-bottom: 3rem;
  }
  
  @media (min-width: $lg) {
    margin-bottom: 4rem;
  }
}

.mb-big {
  margin-bottom: 8rem;
}

.table-responsive {
  width: 100%;
  overflow-x: auto;
  display: block;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.iframe-wrap {
  max-width: 90rem;
  margin: 0 auto 2rem;
  background: #f5f5f5;
}

.iframe-main {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}

.iframe-wrap iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}
/* Misc Global Classes
------------------------------------------ */