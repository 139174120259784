.section {
  $self: &;

  padding: 4rem 0;
  position: relative;

  @media (min-width: $md) {
    padding: 6rem 0;
  }

  @media (min-width: $lg) {
    padding: 9rem 0;
  }

  &--small {
    padding: 4.5rem 0;
  }

  &--partners-gray {
    padding: 0;

    .partners-section {
      padding: 4rem 0;

      @media (min-width: $md) {
        padding: 6rem 0;
      }

      @media (min-width: $lg) {
        padding: 9rem 0;
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2.5rem;
    flex-wrap: wrap;
    position: relative;
    padding: 0 16rem 0 0;

    @media (min-width: $md) {
      margin-bottom: 5rem;
      flex-wrap: nowrap;
      padding: 0;
    }

    #{$self}__heading {
      margin-bottom: 0;
    }

    @media (max-width: $sm--mw) {
      &--bottom-controls {
        position: static;
        padding-right: 0;

        .section__controls {
          .swiper-button-prev,
          .swiper-button-next {
            position: absolute;
            bottom: 4.5rem;
            top: auto;
            transform: translateX(-15px);
            z-index: 2;
          }
        }
      }
    }
  }

  &__heading {
    margin-bottom: 3.5rem;
    position: relative;
    line-height: 1;
    font-size: 3.3rem;

    @media (min-width: $md) {
      margin-bottom: 5rem;
      font-size: 3.8rem;
    }
  }

  &__controls {
    display: flex;
    align-items: center;
    flex: 0 0 100%;

    @media (min-width: $md) {
      margin: 0;
      flex: initial;
    }

    .category {
      margin-right: 6rem;
    }

    .swiper-button-prev,
    .swiper-button-next {
      position: absolute;
      top: 0;

      @media (min-width: $sm) {
        top: -1rem;
      }

      @media (min-width: $md) {
        position: relative;
      }
    }

    .swiper-button-prev {
      right: 7rem;

      @media (min-width: $md) {
        right: 0;
      }
    }

    .swiper-button-next {
      right: 0;
    }
  }

  &__desc {
    font-size: 1.8rem;
    font-weight: 500;
    color: #585857;
    margin-bottom: 3rem;
    margin-top: -2.5rem;
  }

  &--gray {
    background: #f8f8f8;
  }

  &--sm {
    padding: 4.5rem 0;
  }
}

.graphic-section {
  position: relative;
  padding: 4rem 0;
  background: #F8F8F8;
  overflow: hidden;

  @media (min-width: $xl) {
    padding: 4rem 0 0;
  }

  &__main {
    position: relative;
  }

  &__svg {
    display: none;
    width: 100%;
    color: #CDCDCD;

    @media (min-width: $xl) {
      display: block;
    }
  }

  &__button {
    cursor: pointer;

    &.is-active {
      color: $primary-color;
    }
  }

  &__groups {

    @media (min-width: $xl) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
    }
  }

  &__group {
    @media (max-width: $xl--mw) {
      padding: 2rem;
      background: #FFFFFF;
      border: 1px solid #EEEEEE;
      box-shadow: 0 8px 20px rgba(0, 0, 0, 0.05);
    }

    @media (min-width: $xl) {
      position: absolute;
      z-index: 2;
      padding-top: 11.5rem;
      padding-left: 6.5rem;
      max-width: 48rem;

      &:nth-child(1) {
        top: 218px;
        left: 24px;
      }

      &:nth-child(2) {
        top: 194px;
        left: 193px;
      }

      &:nth-child(3) {
        top: 126px;
        left: 362px;
      }

      &:nth-child(4) {
        top: 81px;
        left: 530px;
      }

      &:nth-child(5) {
        top: 94px;
        left: 698px;
      }

      &:nth-child(6) {
        top: 15px;
        right: 357px;
        padding-top: 20rem;
      }

      &:nth-child(7) {
        top: 36px;
        right: 189px;
        padding-top: 19rem;
      }

      &:nth-child(8) {
        top: 14px;
        right: 24px;
        padding-top: 16rem;
      }

      &:nth-child(6),
      &:nth-child(7),
      &:nth-child(8) {
        padding-left: 0;
        padding-right: 6.5rem;
        text-align: right;

        .graphic-section {
          &__year {
            left: auto;
            right: 0;
          }

          &__title {
            &::before {
              left: auto;
              right: -3.1rem;
            }

            &::after {
              left: auto;
              right: -2.7rem;
            }
          }
        }
      }

      &.is-active {
        .graphic-section__year {
          color: $primary-color;
        }

        .graphic-section__title,
        .graphic-section__excerpt {
          display: block;
        }
      }
    }

  }

  &__year {
    margin: 0 0 1rem;
    color: $primary-color;
    font-size: 5.14rem;
    font-weight: 700;
    line-height: 1.2;

    @media (min-width: $xl) {
      position: absolute;
      top: 0;
      left: 0;
      color: #CDCDCD;
      font-size: 3.6rem;
    }
  }

  &__title {
    position: relative;
    margin: 0 0 1rem;
    font-size: 2.4rem;
    font-weight: 700;
    line-height: 1.3;

    @media (min-width: $xl) {
      margin: 0 0 0.5rem;
      display: none;
      color: $primary-color;

      &::before {
        content: '';
        position: absolute;
        top: 0.9rem;
        left: -3.1rem;
        width: 1.7rem;
        height: 1.7rem;
        background: rgba($primary-color, 0.5);
      }

      &::after {
        content: '';
        position: absolute;
        top: 1.3rem;
        left: -2.7rem;
        width: 0.9rem;
        height: 0.9rem;
        background: $primary-color;
      }
    }

  }

  &__excerpt {
    font-weight: 500;

    @media (min-width: $xl) {
      display: none;
    }
  }
}

.team-section {
  &__inner {
    position: relative;
    padding-bottom: 4rem;

    @media (min-width: $xl) {
      padding-bottom: 0;
    }
  }

  &__summary {
    text-align: center;

    @media (min-width: $xl) {
      margin-top: 5rem;
    }
  }
}
