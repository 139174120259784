.promo-carousel {
  .swiper-slide {
    height: auto;
  }

  .swiper-controls {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    max-width: $layout-max-width;
    width: 100%;

    .swiper-button-prev,
    .swiper-button-next {
      position: absolute;
      box-shadow: 0px 5px 30px rgba($black, 0.1);
      top: 0;
      transform: translateY(-50%);

      &:not(:hover) {
        background: rgba($white, .1);

        .icon {
          fill: $white;
        }
      }
    }

    .swiper-button-prev {
      right: 100%;
    }

    .swiper-button-next {
      left: 100%;
    }
  }

  .swiper-pagination {
    position: absolute;
    bottom: 0;
    z-index: 3;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    padding: 0 1.5rem;
    max-width: $layout-max-width;
    display: flex;
    margin: 0 -1.5rem;

    .swiper-pagination-bullet {
      height: .6rem;
      background: rgba(255, 255, 255, 0.7);
      width: auto;
      flex: 1;
      border-radius: 0;
      border: none;
      margin: 0 1.5rem;

      &-active {
        background: $primary-color;

        &::after {
          display: none;
        }
      }
    }
  }

  .promo-slide {
    &__details {
      z-index: 4;
    }
  }
}

.promo-slide {
  color: $white;
  padding: 11.5rem 0;
  height: 100%;
  margin: 0;
  box-sizing: border-box;

  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  @media (min-width: 1600px) {
    padding: 17rem 0;
  }

  @media (min-width: 1900px) {
    padding: 20rem 0;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
    display: none;
  }

  &::before {
    background: linear-gradient(90deg, #58595B 0%, rgba(88, 89, 91, 0) 100%);
  }

  &::after {
    background: rgba(57, 57, 57, 0.5);
  }

  &__details {
    position: relative;
    text-align: left;
    max-width: $layout-max-width;
    padding: 0 5rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
  }

  &__main {
    max-width: 62rem;
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    position: absolute;
    left: 0;
    top: 0;
    display: none;

    @media (min-width: $sm) {
      display: block;
    }

    &--mob {
      width: 100%;
      display: block;
      height: 100%;
      object-fit: cover;
      object-position: center;
      position: absolute;
      left: 0;
      top: 0;

      @media (min-width: $sm) {
        display: none;
      }
    }
  }

  &__caption {
    font-size: 4rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
    line-height: 1.3;

    opacity: 0;
    
    @media (min-width: $sm) {
      font-size: 5.3rem;
    }

    @at-root .swiper-slide-active & {
      animation: .3s fadeInUp forwards;
    }
  }

  &__desc {
    font-size: 2rem;
    margin-bottom: 4rem;
    font-weight: 600;

    opacity: 0;
    
    @media (min-width: $sm) {
      font-size: 2.4rem;
    }

    @at-root .swiper-slide-active & {
      animation: .3s fadeInUp .3s forwards;
    }
  }

  &__btn {
    opacity: 0;

    @at-root .swiper-slide-active & {
      animation: .3s fadeInUp .6s forwards;
    }
  }
}
