.category {
  $self: &;

  margin: 0;
  display: flex;
  flex-wrap: wrap;

  &__item {
    font-weight: 500;
    font-size: 1.7rem;
    text-transform: uppercase;
    margin-top: 1rem;
    margin-bottom: 1rem;

    &:not(:last-child) {
      margin-right: 3rem;
    }

    &.is-active {
      #{$self}__link {
        cursor: default;
        color: $primary-color;

        &::before {
          background: $primary-color;
        }
      }
    }
  }

  &__link {
    color: #585857;
    transition: all .3s;
    position: relative;
    display: block;
    cursor: pointer;
    padding: 0 0 .2rem;

    &::before {
      content: '';
      width: 100%;
      position: absolute;
      left: 0;
      top: 100%;
      background: #585857;
      height: 1px;
      transition: all .3s;
    }

    &:hover {
      color: $primary-color;

      &::before {
        background: $primary-color;
      }
    }
  }
}
