/**
 * Icons
 */
.icon {
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
  vertical-align: middle;
}

// .fa,
// .fas {
//   font-family: fa;
//   -moz-osx-font-smoothing: grayscale;
//   -webkit-font-smoothing: antialiased;
//   display: inline-block;
//   font-style: normal;
//   font-variant: normal;
//   text-rendering: auto;
//   line-height: 1;
// }

// .fas {
//   font-weight: 900;
// }

// .fa-file-alt::before {
//   content: "\f15c";
// }

// .fa-chevron-down::before {
//   content: "\f078";
// }

// .fa-chevron-up::before {
//   content: "\f077";
// }

// .fa-chevron-left::before {
//   content: "\f053";
// }

// .fa-chevron-right::before {
//   content: "\f054";
// }

// .fa-copy::before {
//   content: "\f0c5"
// }

// .fa-calendar-alt::before {
//   content: "\f073";
// }

// .fa-camera::before {
//   content: "\f030";
// }

// .fa-pen::before {
//   content: "\f304";
// }

// .fa-pencil-alt::before {
//   content: "\f303";
// }

// .fa-trash-alt::before {
//   content: "\f2ed";
// }

// .fa-chevron-double-left::before {
//   content: "\f323";
// }

// .fa-chevron-double-right::before {
//   content: "\f324";
// }

// .fa-quotes-right::before {
//   content: "\f10e";
// }

// .fa-quotes-left::before {
//   content: "\f10d";
// }

// .fa-times-circle::before {
//   content: "\f057";
// }