.hero-intro {
  $self: &;

  background: #f9f9f9;

  &__main {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0;

    @media (min-width: $xl) {
      flex-wrap: nowrap;
      margin: 0 -1.7rem;
    }

    > * {
      flex: 0 0 50%;
      max-width: 50%;
      padding: 0 1.7rem;
    }

    &--stretched {
      align-items: stretch;
      flex-wrap: wrap;
      margin: 0;

      @media (min-width: $xl) {
        flex-wrap: nowrap;
        margin: 0 -1.7rem;
      }

      #{$self}__about {
        padding: 3rem 2rem;
        max-width: 100%;
        flex: 0 0 100%;

        @media (min-width: $xl) {
          padding: 3rem 0;
        }

        @media (min-width: $md) {
          flex: 0 0 50%;
          max-width: 50%;
        }
      }

      #{$self}__about-info {
        &.has-dots {
          &::after {
            transform: translate(-19rem, -24%);
          }

          &--secondary {
            &::after {
              transform: translate(-105%, -20%);
              top: 0;
            }
          }
        }
      }

      #{$self}__heading {
        color: #455B66;
      }

      #{$self}__type {
        font-size: 8.5rem;
        transform: translateY(0);

        &--company {
          font-size: 7.2rem;
          transform: translateY(-1.5rem);
          max-width: 40rem;
          margin-left: auto;
        }
      }
    }
  }

  &__about {
    position: relative;
    padding: 3rem 2rem;
    max-width: 100%;
    flex: 0 0 100%;

    @media (min-width: $xl) {
      padding: 3rem 0;
    }

    @media (min-width: $md) {
      flex: 0 0 50%;
      max-width: 50%;
    }

    &-info {
      max-width: 100%;
      margin-left: auto;
      position: relative;

      @media (min-width: $md) {
        //max-width: calc(1260px / 2);
        max-width: calc(1170px / 2);
      }

      &.has-dots {
        &::after {
          content: '';
          width: 30rem;
          height: 30rem;
          background: url('../images/dots-2.svg') no-repeat center;
          position: absolute;
          left: 0;
          top: 100%;
          transform: translate(-25rem, -37%);
          z-index: 1;
          display: none;

          @media (min-width: $md) {
            display: block;
          }
        }
      }
    }
  }

  &__preview {
    height: 100%;
    flex: 0 0 100%;
    max-width: 100%;

    @media (min-width: $md) {
      flex: 0 0 50%;
      max-width: 50%;
    }

    &-overlay {
      position: relative;
      max-width: 80rem;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(0deg, rgba(54, 77, 88, 0.6) 0%, rgba(56, 77, 88, 0) 100%);
      }

      img {
        width: 100%;
      }
    }

    &-img {
      display: block;
      max-width: 100%;
      height: auto;
    }
  }

  &__promo {
    max-width: 100%;
    margin: 0;
    height: 100%;
    position: relative;
    width: 100%;

    @media (min-width: $md) {
      max-width: 80rem;
    }

    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, #364D58 0%, rgba(56, 77, 88, 0) 100%);
    }

    &-img {
      width: 100%;
      display: block;
      height: 100%;
      object-fit: cover;
    }

    &-details {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      padding: 0 3.5rem 6.5rem;
      z-index: 2;
      color: #fff;
      display: flex;
      margin: 0 -2.2rem;
      line-height: 1.3;

      > * {
        padding: 0 2.2rem;
        max-width: 38rem;
      }
    }

    &-detail {
      margin: 0;

      &-value {
        font-weight: bold;
        color: $primary-color;
        font-size: 4rem;
        margin-bottom: .5rem;
      }

      &-property {
        font-weight: 600;
        font-size: 1.8rem;
        margin: 0;
      }
    }
  }

  &__type {
    position: absolute;
    top: 100%;
    right: 0;
    padding-left: 15rem;
    line-height: 1;
    color: $white;
    text-transform: uppercase;
    font-weight: bold;
    opacity: .8;
    font-size: 10rem;
    transform: translateY(-40%);

    &--small {
      font-size: 6.4rem;
    }
  }

  &__category {
    display: inline-block;
    color: $primary-color;
    font-weight: bold;
    text-decoration: underline;
    text-transform: uppercase;
    margin-bottom: 1.5rem;

    &:hover {
      color: $primary-color;
      text-decoration: none;
    }
  }

  &__heading {
    margin-bottom: 3rem;

    &--long {
      font-size: 3.6rem;
    }
  }

  &__list {
    margin-bottom: 4.5rem;
    line-height: 1.7;

    .list__item {
      margin-bottom: 0;

      &:not(:first-child) {
        margin-top: 0.5rem;
      }

      &::before {
        top: .5rem;
      }
    }
  }

  &__desc {
    margin-bottom: 5rem;
    color: #455B66;
    max-width: 62rem;
    line-height: 1.6;

    + .list {
      margin-top: -3.5rem;
    }

    &--limit {
      max-width: 50rem;
      font-size: 1.8rem;
      line-height: 1.5;
    }

    &--strong {
      font-size: 1.8rem;
      font-weight: 600;
    }
  }

  &__btn {
    position: relative;
    z-index: 2;
  }
}
