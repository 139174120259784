table.table {
  width: 100%;
  border-collapse: collapse;
  font-size: 1.4rem;

  th {
    font-weight: 500;
    padding: 1rem 1.5rem;
    border-bottom: 1px solid $gray--medium;
    font-size: 1.6rem;
  }

  td {
    padding: 1rem 1.5rem;
    border-bottom: 1px solid $gray--medium;
    transition: all .3s;
    position: relative;

    strong {
      font-weight: 500;
    }

    svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  tr:hover td {
    background: $gray--thin;

    &::before {
      background: $primary-color;
    }
  }
}