.page-inner {
  padding: 2rem 0 3rem;

  @media (min-width: $xl) {
    padding-bottom: 7rem;
  }

  &__breadcrumbs {
    margin-bottom: 5rem;

    .breadcrumbs {
      margin: 0;
    }
  }

  &__heading {
    margin: 0 0 5rem;
    font-size: 3.2rem;
    text-align: left;

    @media (min-width: $sm) {
      font-size: 4rem;
    }

    @media (min-width: $lg) {
      font-size: 4.8rem;
    }

    @media (min-width: $xl) {
      font-size: 5.4rem;
    }
  }

  &__content {
    position: relative;

    @media (min-width: $lg) {
      &.has-dots {
        &::before {
          content: '';
          width: 30rem;
          height: 30rem;
          background: url('../images/dots.svg') no-repeat center;
          position: absolute;
          left: 100%;
          top: 0;
          transform: translate(-6rem, -6rem);
          z-index: -1;
        }

        &::after {
          content: '';
          width: 30rem;
          height: 30rem;
          background: url('../images/dots.svg') no-repeat center;
          position: absolute;
          right: 100%;
          top: 0;
          transform: translate(10rem, 11rem);
          z-index: -1;
        }
      }
    }

    .row > div {
      margin-bottom: 3rem;
    }
  }
}
