.has-lines {
  @media (min-width: $md) {
    position: relative;

    &::before {
      content: '';
      max-width: 1290px;
      position: absolute;
      margin: 0 auto;
      left: 0;
      top: 0;
      right: 0;
      height: 100%;
      width: 100%;
      border-left: 1px solid #E7E7E7;
      border-right: 1px solid #E7E7E7;
      pointer-events: none;
    }

    &::after {
      content: '';
      max-width: 645px;
      position: absolute;
      margin: 0 auto;
      left: 0;
      top: 0;
      right: 0;
      height: 100%;
      width: 100%;
      border-left: 1px solid #E7E7E7;
      border-right: 1px solid #E7E7E7;
      background: linear-gradient(to right, transparent 0%, transparent 49.9%, #E7E7E7 49.9%, #E7E7E7 50.1%, transparent 50.1%, transparent 100%);
      pointer-events: none;
    }

    > * {
      position: relative;
      z-index: 2;
    }
  }
}

.has-dots {
  &::before,
  &::after {
    pointer-events: none;
  }
}
