.service-user {
  $self: &;

  background: $white;
  border: 1px solid $primary-color;
  padding: 1.5rem 3.5rem;
  box-shadow: 0 .8rem 2rem rgba($black, 0.05);
  min-height: 9.5rem;
  height: 100%;
  color: #455B66;
  line-height: 1.3;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__category {
    font-size: 2rem;
    font-weight: bold;
  }

  &--lead {
    background: $primary-color;
    color: $white;
    text-transform: uppercase;
    font-size: 2.4rem;
    text-align: center;
    margin: 0 1.5rem 0 1.5rem;
    box-shadow: 0 1rem 5rem rgba(1, 62, 28, 0.25);

    &::before,
    &::after {
      content: '';
      position: absolute;
      z-index: -1;
    }

    &::before {
      left: -5rem;
      right: -5rem;
      top: 50%;
      transform: translateY(-50%);
      border-top: .4rem dotted rgba(94, 186, 153, 0.5);
    }

    &::after {
      left: 50%;
      top: 101%;
      height: 5rem;
      transform: translateX(-50%);
      border-left: .4rem dotted rgba(94, 186, 153, 0.5);
    }

    #{$self}__category {
      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 101%;
        height: 5rem;
        border-left: .4rem dotted rgba(94, 186, 153, 0.5);
        z-index: -1;
      }

      &::before {
        right: 101%;
        transform: rotate(55deg);
        transform-origin: right top;
      }

      &::after {
        left: 101%;
        transform: rotate(-55deg);
        transform-origin: left top;
      }
    }
  }
  
  &.one-lines {
    &::before {
      right: 50%;
    }
    
    .service-user__category::before {
      display: none;
    }
    
    .service-user__category::after {
      display: none;
    }
    
    &::after {
      display: none;
    }
  }
  
  &.two-lines {
    
    .service-user__category::before {
      display: none;
    }
    
    .service-user__category::after {
      display: none;
    }
    
    &::after {
      display: none;
    }
  }
  
  &.three-lines {
    
    .service-user__category::after {
      display: none;
    }
    
    &::after {
      display: none;
    }
  }
  
  &.four-lines {
    .service-user__category::after {
      display: none;
    }
  }
}

.service-users {
  &__heading {
    color: #455B66;
  }

  &__list {
    display: flex;
    flex-wrap: wrap-reverse;
    margin: 0 -1.5rem;

    @media (min-width: $sm) {
      flex-wrap: wrap;
    }
  }

  &__user {
    padding: 0 1.5rem;
    flex: 0 0 100%;
    max-width: 100%;
    margin-top: 3rem;

    &:nth-child(2) {
      order: 1;
    }

    @media (min-width: $sm) {
      flex: 0 0 33.333%;
      max-width: 33.333%;
      margin: 0;

      &:nth-child(2) {
        order: 0;
      }

      &:not(:nth-child(-n+3)) {
        margin-top: 3rem;
      }
    }
  }
}

.service-describe {
  background: $white;
  padding: 4.5rem;
  margin-right: 2rem;
  border: 1px solid #EEEEEE;
  box-shadow: 0 .8rem 2rem rgba($black, 0.05),
              2rem 2rem 0 0 $primary-color;

  &__header {
    margin-bottom: 1rem;
    display: flex;
  }

  &__intro {
    padding-left: 4.5rem;
  }

  &__icon-wrap {
    width: 9.1rem;
    height: 9.1rem;
    position: relative;
    background: $secondary-color;
    flex: none;
  }

  &__icon {
    width: 6.5rem;
    height: 6.5rem;
    fill: $white;

    @include absolute-center;
  }

  &__heading {
    margin-bottom: 1.7rem;
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 1;

    &-link {
      color: $primary-color;
      text-decoration: underline;

      &:hover {
        color: $primary-color;
        text-decoration: none;
      }
    }
  }

  &__excerpt {
    font-size: 1.8rem;
    color: #455B66;
    margin: 0;
  }

  &__subheading {
    font-size: 2.4rem;
    font-weight: bold;
    margin-bottom: 2rem;
    color: #455B66;
    line-height: 1.2;
  }

  &__list {
    font-size: 1.8rem;
    margin-bottom: 2rem;

    li {
      &::before {
        top: .5rem;
      }
    }
  }

  p {
    color: #455B66;
    margin-bottom: 2rem;
    font-size: 2rem;

    strong {
      font-weight: bold;
    }
  }

  &__btn {
    font-size: 1.5rem;
  }
}

.service-describe-section {
  .service-describe {
    margin-bottom: 2rem;
  }
}