.tabs {
  $self: &;

  /* Tabs Items */
  &__header {
    border-bottom: 1px solid #E8E8E8;
  }

  &__list {
    display: inline-flex;
    list-style: none;
    margin: 0;
    padding: 0;
    white-space: nowrap;
  }

  &__item {
    font-size: 1.3rem;
    text-transform: uppercase;
    position: relative;

    &:not(:first-child) {
      margin-left: 1.5rem;
    }
  }

  &__link {
    display: block;
    padding: .9rem .5rem;
    cursor: pointer;
    transition: all .3s;
    color: #222222;
    border-bottom: .4rem solid transparent;

    &:hover {
      text-decoration: none;
      font-weight: 500;
      color: #222222;
    }

    &.is-active {
      cursor: default;
      font-weight: 500;
      border-bottom-color: #7ACE4C;
    }
  }
  /* Tabs Items */

  /* Tabs Body */
  &__panes {}

  &__tab-pane {
    padding: 3rem 0;
    display: none;

    &.is-active {
      display: block;
    }
  }
  /* Tabs Body */

  &--product {
    #{$self}__header {
      text-align: left;
      background: #fafafa;
      margin: 0 -3rem;
    }

    #{$self}__item {
      font-size: 1.6rem;

      &:first-child #{$self}__link {
        padding-left: 3rem;

        &::after {
          left: 3rem;
          width: calc(100% - 5rem);
        }
      }

      &::before {
        border-left-color: #e6e6e6;
      }
    }

    #{$self}__link {
      padding-top: 3rem;
      padding-bottom: 3rem;
      position: relative;

      &::after {
        content: "";
        width: calc(100% - 4rem);
        position: absolute;
        bottom: 1.5rem;
        left: 2rem;
        height: .3rem;
      }

      &.is-active {
        font-weight: 600;
        background: $white;

        &::after {
          background: $primary-color;
        }

        &::before {
          width: 2px;
          content: "";
          position: absolute;
          left: 100%;
          background: $white;
          height: 100%;
          top: 0;
          z-index: 2;
        }
      }
    }

    #{$self}__tab-pane {
      padding-bottom: 5rem;

      p {
        margin-top: 1.5rem;
        color: $black;
        line-height: 1.3;

        &:first-child {
          margin-top: 0;
        }
      }

      .banner {
        text-align: right;

        &__wrap {
          display: inline-block;
          vertical-align: top;
        }
      }
    }
  }
}

.products-promo-section {
  [class*="col-"] { 
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  /*[class*="col-"]:not(:nth-child(-n+3)) {
    padding-top: 3rem;
  }*/

  .tabs__tab-pane {
    padding-bottom: 0;
    padding-top: 2rem;
  }
}

.product-video {
  text-align: center;
  max-width: 87rem;
  margin: 0 auto;

  &__desc {
    margin-bottom: 3rem;

    @media (min-width: $md) {
      margin-bottom: 5rem;
    }
  }

  &__iframe {
    max-width: 70rem;
    margin-bottom: 0;
  }
}