.solution {
  $self: &;

  display: flex;
  align-items: center;
  position: relative;

  &--half {
    #{$self}__img-wrap {
      max-width: 100%;
      flex: 0 0 100%;

      @media (min-width: $md) {
        max-width: 50%;
        flex: 0 0 50%;
      }
    }

    #{$self}__wrap {
      max-width: 100%;
      flex: 0 0 100%;

      @media (min-width: $md) {
        max-width: 50%;
        flex: 0 0 50%;
      }
    }

    #{$self}__main {
      padding-right: 3rem;
    }

    #{$self}__list {
      margin-bottom: 3.5rem;
      font-size: inherit;

      &:last-child {
        margin-bottom: 0;
      }
    }

    #{$self}__btn {
      box-shadow: 0 1rem 5rem rgba(1, 62, 28, 0.25);
    }
  }

  &__img-wrap {
    @media (min-width: $md) {
      display: block;
      max-width: 40%;
      flex: 0 0 40%;
    }

    @media (max-width: $md--mw) {
      height: 20rem;
      position: absolute;
      left: 0;
      right: 0;
      overflow: hidden;
      top: 0;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 30%;
        background: linear-gradient(rgba($white, 0) 0%, rgba($white, 1) 100%);
      }

      img {
        position: absolute;
        transform: translateY(-50%);
        width: 100%;
        top: 50%;
      }
    }
  }

  &__img {
    max-width: 100%;
    display: block;
    height: auto;
    width: 100%;
  }

  &__wrap {
    @media (min-width: $md) {
      max-width: 60%;
      flex: 0 0 60%;
      z-index: 2;
    }
  }

  &__main {
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    box-shadow: 0 .8rem 2rem rgba($black, 0.05);
    padding: 2.5rem;

    @media (min-width: $md) {
      margin: 0 -7rem 0 0;
      padding: 4.5rem 7rem 4.5rem 4.5rem;
    }

    @media (max-width: $md--mw) {
      padding-top: 22rem;
    }
  }

  &__heading {
    color: #585857;
    font-size: 3.3rem;
    font-weight: bold;
    margin-bottom: 2rem;
    line-height: 1.25;
  }

  &__title {
    margin-bottom: 15px;
    font-size: 1.8rem;
    font-weight: 600;
  }

  &__desc {
    margin-bottom: 15px;
  }

  &__type {
    margin-bottom: 1.5rem;
    font-weight: bold;
    color: $primary-color;
    font-size: 2.4rem;
  }

  &__list {
    margin-bottom: 2rem;
    font-size: 1.8rem;
    font-weight: 400;

    .item {
      &::before {
        top: .5rem;
      }
    }
  }

  &__info {
    margin: 0 0 2rem;

    &-value {
      font-weight: bold;
      color: $primary-color;
      margin-bottom: 1.5rem;
      font-size: 4rem;
      line-height: 1.1;
    }

    &-desc {
      font-weight: 500;
      color: #585857;
      font-size: 1.6rem;
    }
  }

  &__btn {
    padding-left: 2.3rem;
    padding-right: 2.3rem;
    font-size: 1.5rem;
  }
}

.solution-section {
  @media (min-width: $lg) {
    padding-bottom: 7rem;
  }

  .category {
    margin-bottom: 3rem;
    padding: 0 1.5rem;
  }

  .swiper-container {
    margin: 0 -1.5rem;

    @media (min-width: $md) {
      margin: -1.5rem -1.5rem 0;
    }
  }

  .swiper-slide {
    padding: 0 1.5rem 2rem;
  }
}
