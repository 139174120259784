.callback-section {
  .callback {
    max-width: 107rem;
    margin: 0 auto;
    position: relative;
    z-index: 2;

    &.has-dots {
      &::before {
        content: '';
        width: 30rem;
        height: 30rem;
        background: url('../images/dots.svg') no-repeat center;
        position: absolute;
        left: 100%;
        top: 100%;
        transform: translate(-15%, -70%);
        z-index: -1;
      }
    }

    &.has-dots--wide {
      &::before {
        display: none;
      }

      &::after {
        content: '';
        width: 59rem;
        height: 30rem;
        background: url(../images/dots-wide.svg) no-repeat center;
        position: absolute;
        left: 100%;
        top: 100%;
        transform: translate(-45%, -65%);
        z-index: -1;
      }
    }
  }

  &--white {
    .callback {
      &.has-dots {
        &::before {
          left: auto;
          right: 100%;
          transform: translate(15%, -73%) rotate(-90deg);
        }
      }
    }
  }
}

.callback {
  $self: &;

  &__main {
    padding: 2.5rem;
    background: $white;
    box-shadow: 0 .8rem 2rem rgba($black, 0.05), -2rem 2rem 0 0 $primary-color;

    @media (min-width: $sm) {
      padding: 5rem;
    }

    > #{$self}__policy {
      padding: 0;
      margin: 2rem 0 0;
    }
  }

  &__heading {
    margin: 0 0 2rem;
  }

  &__desc {
    font-size: 1.6rem;
    margin: 0 0 3.5rem;
    color: #585857;
  }

  &__footer {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    @media (min-width: $md) {
      flex-wrap: nowrap;
    }
  }

  &__btn {
    font-size: 1.5rem;
    width: 100%;
    margin: 0 auto;

    @media (min-width: $sm) {
      width: auto;
    }

    @media (min-width: $md) {
      margin: 0;
    }
  }

  &__policy {
    margin: 0;
    padding: 0 0 0 3.5rem;
    font-size: 1.4rem;

    @media (min-width: $md) {
      display: block;
    }

    a {
      text-decoration: underline;
      color: #575756;

      &:hover {
        text-decoration: none;
      }
    }
  }

  &__privacy {
    margin: 0 0 2.5rem;
    order: -1;
    flex: 0 0 100%;

    @media (min-width: $md) {
      order: 0;
      padding: 0 0 0 3.5rem;
      flex: initial;
      margin: 0;
    }

    .checkbox-label__main {
      color: #575756;
      font-size: 1.4rem;
      padding-left: 3rem;

      &::before {
        width: 2rem;
        height: 2rem;
        border-radius: .5rem;
        background: #F7F7F7;
        border-color: #EFEEEE;
        margin: -.4rem 0 0;
      }
    }

    .checkbox-label__input:checked + .checkbox-label__main::before {
      background: #F7F7F7;
      border-color: #EFEEEE;
    }

    .checkbox-label__input:checked + .checkbox-label__main::after {
      width: 1.6rem;
      height: 1.6rem;
      left: .2rem;
      top: -.1rem;
      background-size: contain;
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='12' viewBox='0 0 16 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14 2L5.75 10L2 6.36364' stroke='%2357BE85' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    }
  }
}

.icon-right {
  fill: none;
  stroke: $white;
  stroke-width: 1px;
}