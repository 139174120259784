// ************************
// | Template main colors |
// ************************
// Brand Color Palette
$primary-color: #5EBA99 !default;
$secondary-color: #4EBCC2 !default;
$tertiary-color: skyblue !default;

// Useful dark colors
$white: #ffffff !default;
$black: #000000 !default;
$dark: #333333 !default;
$dark--medium: #666 !default;
$dark--light: #999 !default;
$dark--thin: #aaa !default;

// Useful gray colors
$gray: #cccccc !default;
$gray--medium: #e6e6e6 !default;
$gray--light: #f7f7f7 !default;
$gray--thin: #fafafa !default;

// Alert colors
$error: #EF7373;
$error--thin: #D13C3C;
$warning: #FFB643;
$warning--thin: #F39C11;
$success: #93D747;
$success--thin: #5D9A19;

// ***************
// | Base styles |
// ***************
$body-bg: #fff !default;
$layout-max-width: 1320px !default;

$spacer: 1rem;

// z-indexes
$baseZIndex: 3 !default;
$flowZIndex: 11 !default;
$dropdownZIndex: 55 !default;
$dropdownAboveZIndex: 77 !default;
$overlayZIndex: 101 !default;
$overlayAboveZIndex: 111 !default;

// Breakpoints
$breakpoints: (
  xs: 480px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);

$xs: 480px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

$xs--mw: 479px;
$sm--mw: 575px;
$md--mw: 767px;
$lg--mw: 991px;
$xl--mw: 1199px;

// **************
// | Typography |
// **************
// Base fonts stack
$font-base-weight: 400 !default;
$font-base-stack: Montserrat, Roboto, Helvetica, Arial, sans-serif !default;
$font-base-size: 1.6rem !default;
$font-base-color: #585857 !default;
$base-line-height: 1.5 !default;

// Headings
$headings-weight: bold !default;
$headings-fonts-stack: Montserrat, Roboto, Helvetica, Arial, sans-serif !default;
$headings-color: #585857 !default;
$headings-line-height: 1.1 !default;

$subheading-color: #343434 !default;

// link colors
$link-color-normal: #4AA6E3 !default;
$link-color-visited: #9964C7 !default;
$link-color-hover: $primary-color !default;

// ********************************************
// | Buttons                                  |
// ********************************************
// Btn Base
$btn-color: #fff !default;
$btn-color-disabled: #A3A3A3 !default;
$btn-radius: 0 !default;
$btn-disabled-bg: #DBDBDB !default;

// Btn Primary
$btn-primary-color: $btn-color !default;
$btn-primary-bg: $primary-color !default;
$btn-primary-hover: mix(black, $primary-color, 15%) !default;
$btn-primary-active: mix(black, $primary-color, 25%) !default;

// Btn Secondary
$btn-secondary-color: $btn-color !default;
$btn-secondary-bg: $secondary-color !default;
$btn-secondary-hover: mix($black, $secondary-color, 15%) !default;
$btn-secondary-active: mix($black, $secondary-color, 25%) !default;

// Btn Tertiary
$btn-tertiary-color: $white !default;
$btn-tertiary-bg: $tertiary-color !default;
$btn-tertiary-hover: mix($black, $tertiary-color, 15%) !default;
$btn-tertiary-active: mix($black, $tertiary-color, 25%) !default;

// Btn Gray
$btn-gray-color: $btn-color !default;
$btn-gray-bg: #ccc !default;
$btn-gray-hover: #eee !default;
$btn-gray-active: #e6e6e6 !default;

// Btn Grad
$btn-grad-bg: linear-gradient(to right, #ff5e4a 0%, #ffc544 100%) !default;
$btn-grad-color: $white !default;
$btn-grad-hover: #ff5e4a !default;

// Btn Statusses
$btn-danger-bg: #ff3000 !default;
$btn-danger-color: $white !default;
$btn-danger-hover: mix(black, $btn-danger-bg, 15%) !default;
$btn-danger-active: mix(black, $btn-danger-bg, 25%) !default;

// Icons
$btn-icon-fill-color: $btn-color !default;

// *********
// | Forms |
// *********
$input-font-size: 1.5rem;

$input-placeholder-color: #4E556A;
$input-typing-color: #222222;

$input-background: #f7f7f7;

$input-border-normal: #EFEEEE;
$input-border-hover: #CCCCCC;
$input-border-focus: $primary-color;

$input-border-radius: .5rem;

$input-focus-shadow: 0 0 1.5rem rgba($input-border-focus, 0.2);

// **********
// | Header |
// **********


// **********
// | Footer |
// **********


// ************
// | Main Nav |
// ************
$main-nav-font-size: 1.5rem;
$main-nav-text-color: #333333;
$main-nav-bg: $white !default;

$first-level-font-size: 1.5rem !default;
$first-level-text-color: #333333;
$first-level-bg-color: #ffffff !default;

$second-level-font-size: 1.4rem !default;
$second-level-text-color: #333333 !default;
$second-level-bg-color: #ffffff !default;

$tertiary-level-size: 1.4rem !default;
$tertiary-level-text-color: #333333 !default;
$tertiary-level-bg-color: #f5f5f5 !default;

$main-nav-catalog-max-width: 21rem !default;
$main-nav-catalog-height: 60rem !default;
