.news-detail {
  &__img {
    margin-bottom: 3rem;

    @media (min-width: $sm) {
      margin-bottom: 0;
    }

    img {
      display: block;
      width: 100%;
    }
  }

  &__date {
    font-weight: 600;
    line-height: 150%;
    color: #888888;
    font-size: 1.6rem;
    display: inline-block;
    margin-bottom: 2rem;
  }

  &__row {
    margin-bottom: 3rem;
  }

  &__video {
    position: relative;
    padding-bottom: 56.25%;

    iframe {
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
    }
  }
}